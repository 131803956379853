import { Container } from "@mui/system";
import Authentication from "../Authentication/Authentication";

const RegistrationForm = () => {
    return (
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 8,
                width: {
                    small: 300,
                    mobile: 300,
                    middle: 400,
                    tablet: 450,
                    large: 450,
                    desktop: 500,
                },
                justifyContent: 'space-around',
                alignItems: 'center',
                borderRadius: '20px',
                padding: '15px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)', 
            }}>
                <Authentication
                    onLogin={() => {console.log("This is login")}} 
                    onRegister={() => {console.log("This is register")}}
                    isLogin={false} 
                    isRegister={true}></Authentication>
            </Container>
    )
}
export default RegistrationForm;