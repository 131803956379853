import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, Grid, TextField, Alert, Snackbar, Button } from '@mui/material';
import { Request } from '../Header/DefaultHeader';
import { Book } from '../../assets/svg/Book';
import { Person } from '../../assets/svg/Person';
import { Time } from '../../assets/svg/Time';
import { Rate } from '../../assets/svg/Rate';
import { Team } from '../../assets/svg/Team';
import { Days } from '../../assets/svg/Days';
import { Instagram } from '../../assets/svg/Instagram';
import { LandingAccordion } from './LangingAccordion';
import { Facebook } from '../../assets/svg/Facebook';
import { Linkedin } from '../../assets/svg/Linkedin';
import { BoxContainer } from './BoxContainer';
import { CustomChipComponent } from './CustomChip';
import { Formik, Form } from 'formik';
import { Heart } from '../../assets/svg/Heart';
import BookSchema from '../../utils/bookSchema';
import axios from 'axios';

const footerLink = {
    borderRadius: '50%', backgroundColor: 'rgba(225, 217, 243, 1)', width: '40px', height: '40px',    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}
  
const borderRadiusProps = {
    box1: '0 20% 0 20%',
    box2: {
        small: '48.1px 48.1px 0px 48.1px',
        mobile: '48.1px 48.1px 0px 48.1px',
        middle: '64.1px 64.1px 0px 64.1px',
        tablet: '74.8px 74.8px 0px 74.8px',
        large: '85.6px 85.6px 0px 85.6px',
        desktop: '85.6px 85.6px 0px 85.6px'
    },
    box4: '0px 0px 0px 50%',
};

const LandingPage = () => {

    const [chipsOpen, setChipsOpen] = useState(false);

    const [chips, setChips] = useState([
        { name: "Knowledge vault", checked: false },
        { name: "Employee onboarding", checked: false },
        { name: "Communication", checked: false },
        { name: "Team management", checked: false },
        { name: "Sales", checked: true },
        { name: "Interview training", checked: false },
        { name: "HR", checked: false },
        { name: "Hiring", checked: false },
        { name: "Reporting", checked: false },
    ]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const formRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleScrollToForm = () => {
        if (formRef.current){
            formRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const getPadding = () => {
        if (windowWidth < 400){
            return 16
        } else if (windowWidth < 800){
            return 40
        }
        else if (windowWidth < 1200){
            return 64
        }
        else return 96
    };

    return (
        <Box sx={{ maxWidth: '100vw', backgroundColor: '#FCFBFF'}}>
            <Box sx={{ display: 'flex', backgroundColor: 'rgba(36, 33, 43, 1)',
            flexDirection: windowWidth > 800 ? 'row' : "column",
            justifyContent: 'space-between', 
            alignItems: windowWidth > 800 ? 'center' : '',
            gap: `${getPadding()}px`,
            pt: 6, pb: 14,
            borderRadius: {
            desktop: '0 0 260px 0',
            large: '0 0 230px 0',
            tablet: '0 0 220px 0',
            middle: '0 0 180px 0',
            mobile: '0 0 100px 0',
            small: '0 0 100px 0',
            paddingLeft: `${getPadding()}px`,
            paddingRight: `${getPadding()}px`,
         }
         }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{color: 'black', backgroundColor: 'white', borderRadius: '20px', padding: {
                    small: '4px 10px',
                    mobile: '4px 10px',
                    middle: '4p 10px',
                    tablet: '5px 12px',
                    large: '5px 12px',
                    desktop: '5px 12px'
                }, mb: 3, display: 'inline-block', fontSize: {
                    small: '6px',
                    mobile: '6px',
                    middle: '7px',
                    tablet: '9px',
                    large: '11px',
                    desktop: windowWidth > 1400 ? '14px' : '11px',
                }, fontFamily: '"Manrope", sans-serif', fontWeight: 600, width: 'fit-content', border: '1px solid rgba(183, 160, 231, 1)'}}>All-In-One Solution for Efficiency and Scalable Growth</Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography sx={{ color: 'white', fontWeight: 700, fontSize: { small: '18px',
                    mobile: '18px',
                    middle: '26px',
                    tablet: '30px',
                    large: '34px',
                    desktop: windowWidth > 1400 ? '52px' : '38px'
                }, fontFamily: '"Manrope", sans-serif',
                maxWidth: {
                    small: '75vw',
                    middle: '65vw',
                    tablet: '65vw',
                    desktop: '40vw'
                }}}>We're <span style={{ color: 'rgba(183, 160, 231, 1)' }}>Smart Solution</span></Typography>
                    <Typography sx={{ color: 'white', fontWeight: 700, fontSize: { small: '18px',
                    mobile: '18px',
                    middle: '26px',
                    tablet: '32px',
                    large: '36px',
                    desktop: windowWidth > 1400 ? '52px' : '42px'
                }, fontFamily: '"Manrope", sans-serif',
                maxWidth: {
                    small: '75vw',
                    middle: '65vw',
                    tablet: '65vw',
                    desktop: '40vw'
                }}}>for <span style={{ color: 'rgba(183, 160, 231, 1)' }}>Outsourcing Providers</span></Typography>  
                </Box>

            </Box>

<Box sx={{ mt: 3,
  }}>

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '17px', justifyContent: 'center', pb: '17px'}}>
            <BoxContainer
                sx={{
                    width: { small: '57vw', mobile: '57vw', middle: '56vw', tablet: windowWidth < 801 ? '56vw' : '220px', large: '250px', 
                        desktop: windowWidth > 1200 ? '350px' : '280px' 
                    },
                    height: { small: '25vw',
                        mobile: '25vw',
                        middle: '24vw',
                        tablet: windowWidth < 801 ? '24vw' : '92.9px',
                        large: '96.5px',
                        desktop: windowWidth > 1200 ? '133px' : '100px'
                    },
                    borderRadius: borderRadiusProps.box1,
                    overflow: 'hidden'
                }}
            >
                <img src="https://res.cloudinary.com/quibench/image/upload/v1731760937/Quibench/kcdnd8jjbqcm50m0yjmm.jpg" alt="" style={{ width: '100%', height: '100%', borderRadius: '0 210px 0 210px', objectFit: 'cover' }} />
            </BoxContainer>
            <BoxContainer sx={{ borderRadius: '50% 50% 0 50%', backgroundColor: 'rgba(225, 217, 243, 1)', 
            width: {
                small: '25vw',
                        mobile: '25vw',
                        middle: '24vw',
                        tablet: windowWidth < 801 ? '24vw' : '92.9px',
                        large: '96.5px',
                        desktop: windowWidth > 1200 ? '140px' :  '105px'
            }, height: {
                        small: '25vw',
                        mobile: '25vw',
                        middle: '24vw',
                        tablet: windowWidth < 801 ? '24vw' : '92.9px',
                        large: '96.5px',
                    desktop: windowWidth > 1200 ? '133px' :'100px'
                    }
            }} />
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '17px', justifyContent: 'center', pb:'17px',}}>
            <BoxContainer 
            sx={{
                    borderRadius: borderRadiusProps.box1,
                    overflow: 'hidden',
                    width: { small: '26vw',
                        mobile: '26vw',
                        middle: '27vw',
                        tablet: windowWidth < 801 ? '26vw' : '92.9px',
                        large: '96.5px',
                        desktop: windowWidth > 1200 ? '160px' :'125px' 
                    },
                    height: { small: '26vw',
                        mobile: '26vw',
                        middle: '27vw',
                        tablet: windowWidth < 801 ? '26vw' : '92.9px',
                        large: '96.5px',
                        desktop: windowWidth > 1200 ? '155px' :'120px'
                    }
                }}
                >
                <img src="https://res.cloudinary.com/quibench/image/upload/v1731761324/Quibench/honz49stu0qzz3yyfmkf.jpg" alt="" style={{ width: '100%', height: '100%', borderRadius: '0 50% 50% 50%', objectFit: 'cover' }} />
            </BoxContainer>
            <BoxContainer sx={{ backgroundColor: 'rgba(183, 160, 231, 1)', borderRadius: {
                small: '10px',
                mobile: '10px',
                middle: '15px',
                tablet: '20px',
                large: '20px',
                desktop: '20px'
            }, 
            width: { small: '25vw',
                mobile: '25vw',
                middle: '25vw',
                tablet: windowWidth < 801 ? '25vw' : '92.9px',
                large: '116.5px',
                desktop: windowWidth > 1200 ? '165px' :'130px'
            },
            height: { small: '25vw',
                mobile: '25vw',
                middle: '25vw',
                tablet: windowWidth < 801 ? '25vw' : '92.9px',
                large: '116.5px',
                desktop: windowWidth > 1200 ? '165px' : '130px' 
            }
            }} />
            <BoxContainer
            sx={{
                    overflow: 'hidden',
                    width: { small: '25vw',
                        mobile: '25vw',
                        middle: '26vw',
                        tablet: windowWidth < 801 ? '26vw' : '92.9px',
                        large: '96.5px',
                        desktop: windowWidth > 1200 ? '155px' : '120px'
                    },
                    height: { small: '25vw',
                        mobile: '25vw',
                        middle: '26vw',
                        tablet: windowWidth < 801 ? '26vw' : '92.9px',
                        large: '96.5px',
                        desktop: windowWidth > 1200 ? '155px' : '120px'
                    }
                }}
                >
                <img src="https://res.cloudinary.com/quibench/image/upload/v1731761297/Quibench/eygnu5snmlecr4c9trzp.jpg" alt="" style={{ width: '100%', height: '100%', borderRadius: '0px 0px 400px 0px', objectFit: 'cover', WebkitTransform: 'scaleX(-1)'}} />
            </BoxContainer>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '17px', justifyContent: 'center'}}>
            <BoxContainer sx={{ backgroundColor: 'rgba(240, 239, 245, 1)', borderRadius: '50%',  width: {
                small: '25vw',
                mobile: '25vw',
                middle: '25vw',
                        tablet: windowWidth < 801 ? '180px' : '92.9px',
                        large: '96.5px',
                        desktop: windowWidth > 1200 ? '133px' : '100px'
            }, height: {
                small: '25vw',
                mobile: '25vw',
                middle: '25vw',
                        tablet: windowWidth < 801 ? '180px' : '92.9px',
                        large: '96.5px',
                    desktop: windowWidth > 1200 ? '133px' : '100px'
            } }} />
            <BoxContainer
                sx={{
                    width: { small: '57vw', mobile: '57vw', middle: '57vw', tablet: windowWidth < 801 ? '57vw' : '220px', large: '250px',
                        desktop: windowWidth > 1200 ? '360px' : '290px'
                    },
                    height: { small: '25vw',
                        mobile: '25vw',
                        middle: '25vw',
                        tablet: windowWidth < 801 ? '180px' : '92.9px',
                        large: '96.5px',
                        desktop: windowWidth > 1200 ? '133px' : '100px'
                    },
                    borderRadius: borderRadiusProps.box2,
                    overflow: 'hidden'
                }}
            >
                <img src="https://res.cloudinary.com/quibench/image/upload/v1731761352/Quibench/jsrsofdz8adeifhukrns.jpg" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} />
            </BoxContainer>
        </Box>
        </Box>
</Box> 

        <Box sx={{ 
             display: 'flex', gap: `${getPadding() / 2}px`, flexDirection: {
            small: 'column', mobile: 'column', middle: 'column', tablet: 'row', large: 'row', desktop: 'row'
        }, justifyContent: 'space-between', alignItems: 'center', mt: {
            desktop: '160px',
            large: '120px',
            tablet: '120px',
            middle: '80px',
            small: '60px',
            mobile: '60px'
        }, mb: {
            desktop: '160px',
            large: '120px',
            tablet: '120px',
            middle: '80px',
            small: '60px',
            mobile: '60px'
        }, paddingLeft: `${getPadding()}px`,
        paddingRight: `${getPadding()}px`, backgroundColor: '#FCFBFF'}}>

        <Box sx={{ display: windowWidth > 800 ? 'inline' : 'none',  borderRadius: '0 25% 25% 25%', flex: 0.85, width: {
            tablet: '40vw', 
            large: '40vw', 
            desktop: '50vw'
        }, height: {
            tablet: '20vw', 
            large: '20vw', 
            desktop: '25vw'
        }}}>
        <img width="100%"
                    height="100%"
                    style={{
                    objectFit: 'cover', 
                    borderRadius: '0 85px 85px 85px',
                }} src="https://res.cloudinary.com/quibench/image/upload/v1731761788/Quibench/daj75rqenh4jbf4bmigb.jpg" alt="" />
        </Box>

                <Box sx={{ display : 'flex', flexDirection: 'column', textAlign: 'center', alignItems:'center', mt: 4, flex: 1.5}}>

                    <Typography sx={{ mb: 2, fontFamily: '"Manrope", sans-serif', fontSize: {
                        small: '13px',
                        mobile: '13px',
                        middle: '15px',
                        tablet: '17px',
                        large: '18px',
                        desktop: '20px',
                    }, maxWidth: '600px', ml: 'auto', mr: 'auto'}}>
                    Quibench is complex solution for effective outsource group. Select one or more key areas for your use:
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            columnGap: '10px',
                            rowGap: '10px',
                            maxWidth: '600px'
                        }}
                        >
                        {chips.map((item, index) => (
                            <CustomChipComponent
                            key={index}
                            chip={item}
                            setChips={setChips}
                            sx={{
                                flexBasis: '33%',
                                textAlign: 'center',
                            }}
                            />
                        ))}
                        </Box>
                    <Box sx={{ margin: '20px auto'}} onClick={handleScrollToForm}>
                    <Request fit={true} />
                    </Box>
                </Box>
        </Box>

        <Box sx={{ 
            backgroundColor: 'rgba(227, 219, 244, 1)', 
            display: 'flex', 
            pt: {
                small: '50px',
                mobile: '50px',
                middle: '50px',
                tablet: '100px',
                large: '100px',
                desktop: '100px'
            }, pb: {
                small: '220px',
                mobile: '220px',
                middle: '170px',
                tablet: '170px',
                large: '190px',
                desktop: '180px'
            },
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '0 0 0 300px',
            paddingLeft: `${getPadding()}px`,
            paddingRight: `${getPadding()}px`,
            backgroundImage: 'url(https://res.cloudinary.com/quibench/image/upload/c_pad,h_250,w_370/Quibench/lfww6wfzaxzzwofntzmm)',
            backgroundRepeat: 'repeat',
            backgroundPosition: 'start'
        }}>

            <Typography sx={{ color: 'black',
            fontSize: {
                small: '22px',
                mobile: '22px',
                middle: '28px',
                tablet: '32px',
                large: '36px',
                desktop: '40px'
            },
            paddingBottom: '20px',
            fontFamily: '"Manrope", sans-serif',
            fontWeight: 600
            }}>UNLOCK NEW LEVELS OF PRODUCTIVITY</Typography>

<Box sx={{
        margin: '0 auto',
        borderRadius: '50px',
        padding: windowWidth > 1000 ? `20px ${getPadding()}px` : ''
    }}>
    <Grid 
        container 
        spacing={4} 
        sx={{ alignItems: 'stretch' }}>
        {[
            {
                icon: <Book />,
                title: "Knowledge Core",
                description: "A secure hub providing instant access to key knowledge, client insights, and solutions. Quickly find the resources you need to solve challenges, streamline workflows, and boost team productivity."
            },
            {
                icon: <Person />,
                title: "Interview & Onboarding Companion",
                description: "AI-driven tools that streamline interview preparation, automate onboarding tasks, and guide technical specialists for faster adaptation and skill development on projects."
            },
            {
                icon: <Time />,
                title: "Recruitment & HR Tools",
                description: "Automates recruitment workflows, simplifies onboarding, and tracks performance metrics to save time, reduce errors, and enhance the efficiency of HR processes."
            },
            {
                icon: <Team />,
                title: "Metrics & Resource Utilization",
                description: "Provides actionable insights by tracking KPIs such as team performance, resource allocation, and growth trends, helping you refine strategies and achieve better results."
            },
        ].map((item, index) => (
            <Grid 
                item 
                middle={12} 
                tablet={6} 
                key={index} 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'start',
                        justifyContent: 'space-between',
                        backgroundColor: '#FCFBFF',
                        padding: '20px',
                        borderRadius: '20px',
                        flex: 1,
                        boxSizing: 'border-box',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: {
                                small: '40px',
                                mobile: '40px',
                                middle: '45px',
                                tablet: '45px',
                                large: '50px',
                                desktop: '50px',
                            },
                            maxHeight: {
                                small: '40px',
                                mobile: '40px',
                                middle: '45px',
                                tablet: '45px',
                                large: '50px',
                                desktop: '50px',
                            },
                        }}
                    >
                        {item.icon}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3, flex: 1 }}>
                        <Typography
                            sx={{
                                fontFamily: '"Manrope", sans-serif',
                                mb: {
                                    desktop: 2.5,
                                    large: 2.5,
                                    tablet: 2.5,
                                    middle: 2,
                                    mobile: 1.5,
                                    small: 1.5,
                                },
                                fontWeight: 600,
                                fontSize: {
                                    small: '15px',
                                    mobile: '15px',
                                    middle: '17px',
                                    tablet: '18px',
                                    large: '22px',
                                    desktop: '22px',
                                },
                            }}
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: '"Manrope", sans-serif',
                                color: 'rgba(106, 103, 113, 1)',
                                fontSize: {
                                    small: '12px',
                                    mobile: '12px',
                                    middle: '14px',
                                    tablet: '14px',
                                    large: '16px',
                                    desktop: '16px',
                                },
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {item.description}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        ))}
    </Grid>
</Box>


        </Box>

        <Box sx={{ display: 'flex',
        flexDirection: windowWidth < 800 ? 'column' : 'row', 
        justifyContent: 'space-between',
        gap: '30px',
         alignItems: 'center', marginTop: {
            small: '80px',
            mobile: '80px',
            middle: '120px',
            tablet: '120px',
            large: '160px',
            desktop: '160px'
        }, marginBottom: {
            small: '80px',
            mobile: '80px',
            middle: '120px',
            tablet: '120px',
            large: '160px',
            desktop: '160px'
        },
        paddingLeft: `${getPadding()}px`,
        paddingRight: `${getPadding()}px`,
        backgroundColor: '#FCFBFF'
        }}>
            <Box sx={{ 
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexGrow: 0.75, 
                alignSelf: windowWidth > 1200 ? 'center' : 'flex-start'
            }}>
                <Typography sx={{ fontSize: {
                    small: '22px',
                    mobile: '22px',
                    middle: '28px',
                    tablet: '36px',
                    large: '40px',
                    desktop: '46px'
                }, fontFamily: '"Manrope", sans-serif', fontWeight: 600}}>Focus on <span style={{
                    color: 'rgba(183, 160, 231, 1)'
                }}>result</span></Typography>
                <Typography sx={{ mb: '32px', mt: '8px', fontSize: {
                    small: '14px',
                    mobile: '14px',
                    middle: '14px',
                    tablet: '16px',
                    large: '18px',
                    desktop: '20px'
                }, fontFamily: '"Manrope", sans-serif', maxWidth: {
                    desktop: '395px',
                    large: '395px',
                    middle: '300px',
                    tablet: '395px',
                    mobile: '240px'
                } }}>
                    Quibench is complex solution for effective outsource group.
                </Typography>
                <Box onClick={handleScrollToForm}>
                <Request fit={true} />
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px',  flexGrow: 1.5 }}>
            <Box sx={{ alignSelf: windowWidth > 1200 ? 'flex-end' : 'flex-start', width: {
                small: '100%',
                mobile: '100%',
                middle: '100%',
                tablet: windowWidth < 800 ? '100%' : '380px',
                large: '420px',
                desktop: '520px'
            }, 
            height: {
                small: '50vw',
                mobile: '50vw',
                middle: '50vw',
                tablet: windowWidth < 800 ? '50vw' : '220px',
                large: '242px',
                desktop: '300px'
            }}}>
                <Days />
            </Box>

            <Box sx={{ alignSelf: 'flex-start', 
            width: {
                small: '100%',
                mobile: '100%',
                middle: '100%',
                tablet: windowWidth < 800 ? '100%' : '380px',
                large: '420px',
                desktop: '520px'
            }, 
            height: {
                small: '50vw',
                mobile: '50vw',
                middle: '50vw',
                tablet: windowWidth < 800 ? '50vw' : '220px',
                large: '242px',
                desktop: '300px'
            }
            }}>
                <Rate />
            </Box>
            </Box>

        </Box>

        <Box sx={{
            paddingTop: {
                desktop :'120px',
                large: '120px',
                tablet: '80px',
                middle: '80px',
                small: '60px',
                mobile: '60px'
            },
            paddingBottom: {
                desktop :'120px',
                large: '120px',
                tablet: '100px',
                middle: '100px',
                small: '80px',
                mobile: '80px'
            },
            display: 'flex', 
            flexDirection: windowWidth > 800 ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            backgroundColor: 'rgba(36, 33, 43, 1)',
            backgroundImage: `url(https://res.cloudinary.com/quibench/image/upload/v1731859091/Quibench/p5rcxmgzayen83ecoaus.png)`,
            backgroundRepeat: "repeat",
            backgroundSize: "cover",
            borderBottomLeftRadius: {
                small: '120px 100px',
                mobile: '120px 100px',
                middle: '150px 110px',
                tablet: '180px 160px',
                large: '230px 200px',
                desktop: '280px 250px'
            },
            paddingLeft: `${getPadding()}px`,
            paddingRight: `${getPadding()}px`,
            gap: '30px'
        }}>
            <Typography sx={{
                mb: 1,
                fontWeight: 600,
                fontFamily: '"Manrope", sans-serif',
                fontSize: {
                    small: '23px',
                    mobile: '23px',
                    middle: '27px',
                    tablet: '34px',
                    large: '36px',
                    desktop: '40px'
                },
                color: 'white',
            }}>FA<span style={{
                color: 'rgba(183, 160, 231, 1)'
            }}>Q</span></Typography>


            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alingnItems: "center",
                maxWidth: windowWidth > 800 ? '60%' : '100%',
                mt: '-40px'
            }}>

                    <Box sx={{ display: 'none', height: '0px'}} />
                    {[
                        {question: 'What does it cost?', answer: 'Enjoy exclusive access for free until the official launch! To join, simply get in touch with us today \n → '},
                        {question: 'What should I expect during demo call?', answer: `In your demo call, our team will guide you through Quibench's key features, focusing specifically on your organization's needs. We'll address your questions and explore how Quibench can support you in achieving extraordinary results.`}, 
                        {question: 'How secure will my data be?', 
                            answer: `At Quibench, data security is our top priority. We've implemented strong security measures, policies, and procedures to meet required data security standards. We're always working to improve our information security. Here are some key measures we use: \n
                            \u25CB\u00A0 Active bug-finding program \n
                            \u25CB\u00A0 Regular vulnerability scans \n
                            \u25CB\u00A0 Web application firewall \n
                            \u25CB\u00A0 Input data validation \n
                            \u25CB\u00A0 Ongoing security management and monitoring \n
                            \u25CB\u00A0 Daily backups \n
                            Our data center is located in Germany, specifically in Frankfurt.`}]
                    .map((item, index) => {
                        return (
                            <LandingAccordion answer={item.answer} id={index} question={item.question}></LandingAccordion>
                        )
                    })}
                    <Box sx={{ display: 'none'}} />
            </Box>
        </Box>

        <Formik
  validationSchema={BookSchema}
  initialValues={{
    name: '',
    phone: '',
    email: ''
  }}
  onSubmit={(values) => {
    const storedChips = JSON.parse(localStorage.getItem('chips')) || [];
    const storedContacts = JSON.parse(localStorage.getItem('contact')) || [];
    const chipsMatch = JSON.stringify(storedChips) === JSON.stringify(chips);
    const contactsMatch = JSON.stringify(storedContacts) === JSON.stringify({
      phone: values.phone,
      email: values.email
    });
    if (chipsMatch && contactsMatch) {
      if (chipsMatch) {
        setChipsOpen(true);
        return;
      }
    }

    const hasCheckedChip = chips.some(chip => chip.checked);
    if (!hasCheckedChip) {
      console.log("No chips checked");
      return;
    }

    localStorage.setItem('chips', JSON.stringify(chips));
    localStorage.setItem('contact', JSON.stringify({
      phone: values.phone,
      email: values.email
    }));

    axios.post('/api/public/demo/request', {
      knowledgeVault: chips[0]?.checked || false,
      employeeOnboarding: chips[1]?.checked || false,
      communication: chips[2]?.checked || false,
      teamManagement: chips[3]?.checked || false,
      sales: chips[4]?.checked || false,
      interviewTraining: chips[5]?.checked || false,
      hr: chips[6]?.checked || false,
      hiring: chips[7]?.checked || false,
      reporting: chips[8]?.checked || false,
      "name": values.name,
      "phone": values.phone,
      "email": values.email,
    }, { headers: { "Content-Type": "application/json" } })
      .then(() => {
        window.open("https://cal.com/quibench/30min", "_blank");
      })
      .catch(error => {
        console.error("API request failed", error);
      });
  }}
>
  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm }) => (
    <Form onSubmit={handleSubmit}>
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: windowWidth > 800 ? 'row' : 'column',
          justifyContent: 'space-between',
          mt: { small: '80px', mobile: '80px', middle: '120px', tablet: '120px', large: '160px', desktop: '160px' },
          mb: { small: '80px', mobile: '80px', middle: '120px', tablet: '120px', large: '160px', desktop: '160px' },
          alignItems: 'center',
          paddingLeft: `${getPadding()}px`,
          paddingRight: `${getPadding()}px`,
          backgroundColor: '#FCFBFF'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: windowWidth > 800 ? '35%' : '100%',
            mb: 4
          }}
          ref={formRef}
        >
          <Typography
            sx={{
              fontFamily: '"Manrope", sans-serif',
              fontWeight: 600,
              fontSize: { small: '22px', mobile: '22px', middle: '28px', tablet: '34px', large: '40px', desktop: '48px' }
            }}
          >
            Book a demo
          </Typography>
          <TextField
            variant="standard"
            label="Name"
            color="info"
            InputLabelProps={{
              sx: {
                paddingLeft: '16px',
                color: 'gray',
                '&.Mui-focused': { color: 'gray' },
                '&.MuiFormLabel-filled': { color: 'gray' }
              }
            }}
            sx={{ mt: 1, width: '100%', fontFamily: '"Manrope", sans-serif' }}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            name="name"
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            variant="standard"
            label="Phone"
            color="info"
            InputLabelProps={{
              sx: {
                paddingLeft: '16px',
                color: 'gray',
                '&.Mui-focused': { color: 'gray' },
                '&.MuiFormLabel-filled': { color: 'gray' }
              }
            }}
            sx={{ mt: 1, width: '100%', fontFamily: '"Manrope", sans-serif' }}
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            name="phone"
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          <TextField
            variant="standard"
            label="Email"
            color="info"
            InputLabelProps={{
              sx: {
                paddingLeft: '16px',
                color: 'gray',
                '&.Mui-focused': { color: 'gray' },
                '&.MuiFormLabel-filled': { color: 'gray' }
              }
            }}
            sx={{ mt: 1, width: '100%', fontFamily: '"Manrope", sans-serif' }}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            name="email"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />

          <Button
            type="submit"
            sx={{ mt: 6, width: '100%' }}
          >
            <Request fit={false} />
          </Button>
        </Box>

        <Box
          sx={{
            display: windowWidth > 800 ? 'block' : 'none',
            borderRadius: {
              tablet: '160px 160px 0 160px',
              large: '190px 190px 0 190px',
              desktop: '220px 220px 0 220px'
            },
            maxHeight: { tablet: '280px', large: '280px', desktop: '350px' },
            overflow: 'hidden',
            objectFit: 'cover',
            width: windowWidth > 800 ? '55%' : '100%',
          }}
        >
          <img
            src="https://res.cloudinary.com/quibench/image/upload/v1731761807/Quibench/btbkhk1ycbcxjzhympyj.jpg"
            width="100%"
            height="100%"
            alt=""
            style={{ objectFit: 'cover', objectPosition: 'center center -20px' }}
          />
        </Box>
      </Box>
    </Form>
  )}
</Formik>

      <Snackbar
        open={chipsOpen}
        autoHideDuration={5000}
        onClose={() => setChipsOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setChipsOpen(false)} severity={'error'} sx={{ width: '100%' }}>
            Demo can not be booked with same options. Please, make sure at least 1 is chosen.
        </Alert>
      </Snackbar>

{windowWidth < 521 && (
  <Box sx={{
    backgroundColor: 'rgba(36, 33, 43, 1)',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 5, pb: 4,
    borderRadius: '90px 0 0 0',
    paddingLeft: `${getPadding()}px`,
    paddingRight: `${getPadding()}px`,
  }}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '140px',
      mb: 3
    }}>
      <Box sx={footerLink}>
        <Instagram />
      </Box>
      <Box sx={footerLink}>
        <Facebook />
      </Box>
      <Box sx={footerLink}>
        <Linkedin />
      </Box>
    </Box>

    <Typography sx={{
      color: 'gray',
      fontSize: '13px',
      fontFamily: '"Manrope", sans-serif',
      mb: 3
    }}>
      Privacy Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span> Cookie Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span> Contact Us
    </Typography>

    <Typography sx={{
      color: 'white',
      fontSize: '15px',
      fontFamily: '"Manrope", sans-serif',
    }}>
      @2024 QUIBENCH
    </Typography>
    <Box sx={{
      color: 'gray',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: '10px'
    }}>
      <Typography sx={{ fontSize: '10px' }}>Made with &nbsp;</Typography>
      <Heart />
      <Typography sx={{ fontSize: '10px' }}>&nbsp; in Poland</Typography>
    </Box>
  </Box>
)}

{windowWidth < 801 && windowWidth > 520 && (
  <Box sx={{
    backgroundColor: 'rgba(36, 33, 43, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 5, pb: 4,
    borderRadius: '90px 0 0 0',
    paddingLeft: `${getPadding()}px`,
    paddingRight: `${getPadding()}px`,
  }}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '140px',
    }}>
      <Box sx={footerLink}>
        <Instagram />
      </Box>
      <Box sx={footerLink}>
        <Facebook />
      </Box>
      <Box sx={footerLink}>
        <Linkedin />
      </Box>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
      <Typography sx={{
        color: 'white',
        fontSize: '15px',
        fontFamily: '"Manrope", sans-serif', mr: 4
      }}>
        @2024 QUIBENCH
      </Typography>

      <Typography sx={{
        color: 'gray',
        fontSize: '13px',
        fontFamily: '"Manrope", sans-serif',
      }}>
        Privacy Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span> Cookie Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span> Contact Us
      </Typography>
    </Box>
    <Box sx={{
      mt: '10px',
      color: 'gray',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 'auto', marginRight: 'auto',
      justifyContent: 'flex-end',
    }}>
      <Typography sx={{ fontSize: '10px' }}>Made with &nbsp;</Typography>
      <Heart />
      <Typography sx={{ fontSize: '10px' }}>&nbsp; in Poland</Typography>
    </Box>
  </Box>
)}

{windowWidth > 801 && (
  <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(36, 33, 43, 1)', pt: 5, pb: 4, borderRadius: '90px 0 0 0' }}>
    <Box sx={{
      borderRadius: '90px 0 0 0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      paddingLeft: `${getPadding()}px`,
      paddingRight: `${getPadding()}px`,
    }}>
      <Typography sx={{
        color: 'white',
        fontSize: '15px',
        fontFamily: '"Manrope", sans-serif',
      }}>
        @2024 QUIBENCH
      </Typography>

      <Box sx={{
        position: 'absolute',
        left: windowWidth > 850 ? '50%' : '45%',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '140px',
      }}>
        <Box sx={footerLink}>
          <Instagram />
        </Box>
        <Box sx={footerLink}>
          <Facebook />
        </Box>
        <Box sx={footerLink}>
          <Linkedin />
        </Box>
      </Box>

      <Typography sx={{
        color: 'gray',
        fontSize: '13px',
        fontFamily: '"Manrope", sans-serif',
      }}>
        Privacy Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span>
        Cookie Policy <span style={{ color: 'white' }}>&nbsp; &#x2022; &nbsp;</span>
        Contact Us
      </Typography>
    </Box>

    <Box sx={{
      mt: '10px',
      color: 'gray',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: `${getPadding()}px`,
      justifyContent: 'flex-end',
    }}>
      <Typography sx={{ fontSize: '10px' }}>Made with &nbsp;</Typography>
      <Heart />
      <Typography sx={{ fontSize: '10px' }}>&nbsp; in Poland</Typography>
    </Box>
  </Box>
)}


</Box>
)}

export default LandingPage;