import { Container, Box,Typography, Avatar, Button } from '@mui/material';
import Spaces from './Spaces';
import { useDispatch, useSelector } from 'react-redux';
import { setElement } from '../../redux/profileSlice';
import { useEffect, memo } from 'react';
import { setWorkSpace } from '../../redux/workspaceSlice';
import { useNavigate } from 'react-router-dom';
import { clearStorage } from '../../store';

export const buttonStyle = {
    color: 'black',
    width: '100%',
    fontWeight: 550,
    padding: '2px 0',
    '&:hover': {
        color: 'lightgray',
        backgroundCOlor: '#f5f5f5'
    },
    fontSize: {
        small: '10px',
        mobile: '10px',
        middle: '10px',
        tablet: '11px',
        large: '13px',
        desktop: '13px'
    }
}

const ProfileButton = memo(() => {

    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.auth.accessToken);
    const user = useSelector(state => state.login.user);
    const navigate = useNavigate();
        
    return (
        <Container sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            boxShadow: 0,
            width: '100%',
            padding: '10px',
            backgroundColor: 'white',
            '&:hover': { backgroundColor: 'white' }
        }}>
            
            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                <Avatar sx={{color: 'black'}}></Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', pl: 1 }}>
                <Typography  sx={{
                    fontSize: {
                        small: '8px',
                        mobile: '7px',
                        middle: '9px',
                        tablet: '11px',
                        large: '13px',
                        desktop: '15px'
                      },
                      fontWeight: 600
                }}>{user.firstName || '' + ' ' + user.middleName || '' + ' ' + user.lastName || ''}</Typography>
                    <Typography sx={{
                          fontSize: {
                            small: '7px',
                            mobile: '7px',
                            middle: '9px',
                            tablet: '11px',
                            large: '13px',
                            desktop: '13px'
                        },
                        color: 'gray'
                    }}>{user.contactEmail || ''}</Typography>
                </Box>
            </Box>

            <Typography sx={buttonStyle} onClick={() => {
                dispatch(setElement('profile')); navigate('/profile')
            }}>Profile</Typography>
            <Typography sx={buttonStyle}>Settings</Typography>
            <Spaces></Spaces>

            <Button sx={buttonStyle} onClick={() => {
                clearStorage();
                navigate('/');
            }}>
                Log out
            </Button>
        </Container>
   
    )
});

export default ProfileButton;
