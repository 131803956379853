import { Box, Button, Typography, IconButton, FormControl, Select, MenuItem, InputLabel, Modal, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, memo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Formik, Form, Field } from 'formik';
import { addGroup } from '../../redux/groupSlice';
import * as Yup from 'yup'
import { removeGroup } from '../../redux/groupSlice';
import { fetchAllGroups, fetchLeader, fetchMembers } from '../../redux/groupSlice';


const schema = Yup.object({
    group: Yup.string().required('Group must have name')
})

const Groups = memo(() => {

    const defaultGroup = {
        name: 'Not chosen',
        id: null
    }

    const defaultLeader = {
        firstName: 'No leader',
        middleName: '',
        lastName: '',
        id: null
    }

    const accessToken = useSelector(state => state.auth.accessToken);
    const allGroups = useSelector(state => state.group.groups);
    const spaceId = useSelector(state => state.workspace.selectedSpace);
    const user = useSelector(state => state.login.user);
    const dispatch = useDispatch();

    const [selectedGroup, setSelectedGroup] = useState(defaultGroup);
    const [members, setMembers] = useState([]);
    const [leader, setLeader] = useState(defaultLeader);
    const [addGroupState, setAddGroupState] = useState(false);
    const [newName, setNewName] = useState('');

    const handleChange = (event) => {
        const group = allGroups.find(group => group.name === event.target.value);
        if (selectedGroup) {
            setSelectedGroup(group)
        }
    };

    const closeGroupModal = () => {
        setAddGroupState(false);
    }

    const setLeaderAndMembers = (group) => {
            dispatch(fetchLeader(accessToken, spaceId, group.id)).then(response => setLeader(response));
            dispatch(fetchMembers(accessToken, spaceId, group.id)).then(response => setMembers(response));
    }

    useEffect(() => {
        const fetchInfo = async () => {
            dispatch(fetchAllGroups(accessToken, spaceId));
        }
        fetchInfo();
    }, []);

    useEffect(() => {

        console.log(allGroups)
        if (!selectedGroup.id && allGroups.length > 0){
            setSelectedGroup(allGroups[0]);            
        }
        else if (allGroups.length === 1){
            setSelectedGroup(allGroups[0]);
        }
        else if (allGroups.length === 0){
            setSelectedGroup(defaultGroup);
            setLeader(defaultLeader);
            setMembers([]);
        }

    }, [allGroups]);

    useEffect(() => {
        if (selectedGroup.id){
            setLeaderAndMembers(selectedGroup);
        }
    }, [selectedGroup])

    return (
        <>
            <Box sx={{ width: '100%' }}>
               <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mb: 1
                }}>
                    <FormControl
                        sx={{
                            borderRadius: 0,
                            width: {
                                small: '70px',
                                mobile: '70px',
                                middle: '130px',
                                tablet: '160px',
                                large: '200px',
                                desktop: '200px',
                            },
                        }}
                    >
                        <InputLabel
                            sx={{
                                fontSize: {
                                    small: '11px',
                                    mobile: '12px',
                                    middle: '13px',
                                    tablet: '13px',
                                    large: '14px',
                                    desktop: '14px',
                                },
                                mt: 1,
                            }}
                        >
                            Group
                        </InputLabel>
                       <Select
                            sx={{
                                '&::placeholder-shown': {
                                    color: '#ccc',
                                    fontSize: '13px',
                                }
                            }}
                            variant="standard"
                            onChange={handleChange}
                            value={selectedGroup.name}
                            label="Group"
                        >
                            {allGroups.length > 0
                                ? allGroups.map((group) => (
                                    <MenuItem key={group.id} value={group.name}>
                                        {group.name}
                                    </MenuItem>
                                ))
                                : (
                                    <MenuItem key="NotChosen" value="Not chosen" onClick={() => {
                                        setSelectedGroup(defaultGroup)
                                    }}>
                                        Not chosen
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                    <Button sx={{
                        ml: 'auto',
                        backgroundColor: 'gray',
                        color: 'white',
                        fontSize: {
                            small: '9px',
                            mobile: '9px',
                            middle: '10px',
                            tablet: '11px',
                            large: '11px',
                            desktop: '12px',
                        },
                        '&:hover': {
                            backgroundColor: 'lightgray'
                        },
                        paddingLeft: 2, paddingRight: 2,
                        maxHeight: '35px'
                    }} onClick={() => { setAddGroupState(true) }}>New group</Button>
                </Box>

                <Box sx={{
                    mb: 1, p: 1, mt: 2,
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>
                  <Typography sx={{
                        fontSize: {
                            small: '11px',
                            mobile: '11px',
                            middle: '12px',
                            tablet: '13px',
                            large: '14px',
                            desktop: '14px',
                        },
                    }}>Group: {selectedGroup.name}</Typography>
                    <Typography sx={{
                        fontSize: {
                            small: '11px',
                            mobile: '11px',
                            middle: '12px',
                            tablet: '13px',
                            large: '14px',
                            desktop: '14px',
                        },
                    }}>Size: {members.length}</Typography>
                   <Typography sx={{
                        fontSize: {
                            small: '11px',
                            mobile: '11px',
                            middle: '12px',
                            tablet: '13px',
                            large: '14px',
                            desktop: '14px',
                        },
                    }}>Leader: {leader.firstName || '' } {" "} {leader.middleName || '' } {" "} {leader.lastName || ''}</Typography>
                    <Box sx={{
                        mt: 2,
                        alignSelf: 'flex-end'
                    }}>
                        <Button sx={{
                            ml: 'auto',
                            backgroundColor: 'gray',
                            color: 'white',
                            fontSize: {
                                small: '9px',
                                mobile: '9px',
                                middle: '10px',
                                tablet: '10px',
                                large: '12px',
                                desktop: '12px',
                            },
                            '&:hover': { backgroundColor: 'lightgray' },
                            paddingLeft: 2,
                            paddingRight: 2,
                            maxHeight: '35px'
                        }}>Edit</Button>
                        <Button
                            sx={{
                                backgroundColor: 'gray',
                                color: 'white',
                                fontSize: {
                                small: '9px',
                                mobile: '9px',
                                middle: '10px',
                                tablet: '10px',
                                large: '12px',
                                desktop: '12px',
                                },
                                '&:hover': {
                                backgroundColor: 'lightgray',
                                },
                                paddingLeft: 2,
                                paddingRight: 2,
                                maxHeight: '35px',
                                ml: 1.5,
                            }}
                            onClick={() => {
                                dispatch(removeGroup(accessToken, selectedGroup.id, spaceId));
                            }}
                            >
                            Delete
                            </Button>

                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 1
                }}>
                    <Typography variant="h5"> Members: </Typography>
                    <Button variant="grButton" sx={{
                        fontSize: {
                            small: '9px',
                            mobile: '9px',
                            middle: '10px',
                            tablet: '11px',
                            large: '12px',
                            desktop: '14px',
                        },
                    }}>Add To Group</Button>
                </Box>

                <Box sx={{
                    maxHeight: '110px', overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    pt: 2,
                    pb: 2,
                    pl: 1,
                    pr: 1,
                    mt: 2
                }}>
                    {members.length > 0 && members.map((item, index) => {
                        return (
                            <Box key={index} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                borderRadius: '20px',
                                alignItems: 'center'
                            }}>
                                <Typography sx={{
                                    fontSize: {
                                        small: '10px',
                                        mobile: '10px',
                                        middle: '12px',
                                        tablet: '14px',
                                        large: '14px',
                                        desktop: '15px'
                                    }
                                }}>{index + 1}{'. '} {item.firstName || ''}{" "} {item.lastName || ''} </Typography>
                                <IconButton>
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                        );
                    })}
                </Box>
            </Box>

            <Modal open={addGroupState} onClose={closeGroupModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                        small: '80vw',
                        mobile: '80vw',
                        middle: '50vw',
                        tablet: '40vw',
                        large: '40vw',
                        desktop: '50vw',
                    },
                    height: '30vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                }}>
                    <Typography variant="h4">Add group</Typography>
                    <Formik initialValues={{ group: '' }} 
                    onSubmit={() => { }} validationSchema={schema}>
                        {({ setFieldValue, values, errors, touched, resetForm }) => (
                            <Form>
                                <Field
                                    as={TextField}
                                    placeholder="New group name"
                                    variant="standard"
                                    name="group"
                                    value={values.group}
                                    onChange={(e) => {
                                        setFieldValue('group', e.target.value);
                                        setNewName(e.target.value);
                                    }}
                                    error={touched.group && Boolean(errors.group)}
                                    helperText={touched.group && errors.group}
                                />
                                <Button type="submit" onClick={() => {
                                    dispatch(addGroup(accessToken, {
                                        "name": newName,
                                        "leaderId": user.userId
                                    }, spaceId))
                                    setAddGroupState(false);
                                    resetForm();
                                }}
                                >Submit</Button>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </>
    )
});

export default Groups;
