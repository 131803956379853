import React, { memo } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken } from '../../redux/authSlice';
import { setRefreshToken } from '../../redux/authSlice';
import axios from 'axios'
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAdmin } from '../../redux/loginSlice';
import { setExpiresIn } from '../../redux/authSlice';
import { loggedIn } from '../../redux/loginSlice';
import { startInterval } from '../../redux/authSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    small: 270,
    mobile: 270,
    middle: 300,
    tablet: 400,
    large: 400,
    desktop: 400,
  },
  bgcolor: 'background.paper',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)', 
  p: 4,
  borderRadius: '25px'
};

const VerificationModal = memo(({ open, onClose, isLogin, isRegistration }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const authToken = useSelector(state => state.auth.authToken);
  const email = useSelector(state => state.login.user.email);

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async (values) => {
      if (isRegistration){
        const response = await axios.post(`/api/public/auth/email-verification?code=${values.code}`, {}, {
          headers: {
            Authorization: "Bearer " + authToken
          }
        });
        if (response.data.access_token){
          dispatch(setAccessToken(response.data.access_token));
          dispatch(setRefreshToken(response.data.refresh_token));
          dispatch(setExpiresIn(response.data.expires_in));
          dispatch(startInterval(response.data.refresh_token, response.data.expires_in))
          dispatch(loggedIn({ user: values }));
          onClose();
          showSnackbar('Registration completed successfully.', 'success');
          navigate('/dashboard');
          if (values.email === 'admin@gmail.com'){
            dispatch(setAdmin());
          }
        } else {
          showSnackbar('Incorrect code. Please try again.', 'warning');
        }
      }
      if (isLogin){
        const response = await axios.post(`/api/public/auth/email-verification?code=${values.code}`, {}, {
          headers: {
            Authorization: "Bearer " + authToken
          }
        });
        if (response.data.access_token){
          dispatch(setAccessToken(response.data.access_token));
          dispatch(setRefreshToken(response.data.refresh_token));
          dispatch(setExpiresIn(response.data.expires_in));
          dispatch(startInterval(response.data.refresh_token, response.data.expires_in));
          dispatch(loggedIn({ user: values }));
          onClose();
          showSnackbar('Verification completed successfully.', 'success');
          navigate('/dashboard');
          if (values.email === 'admin@gmail.com'){
            dispatch(setAdmin());
          }
        } else {
          showSnackbar('Incorrect code. Please try again.', 'warning');
        }
      }
    }
  });

  return (

    <>
    
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={style}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          pb: 3
        }}>
          <Typography variant="h6" component="h2">
            Check your email
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              padding: '10px',
              borderRadius: '50%',
              border: '0.5px solid gray',
              color: 'gray'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography sx={{ mb: 2, textAlign: 'center', fontWeight: 500, fontSize: {
          small: '12px',
          mobile: '12px',
          middle: '14px',
          tablet: '14px',
          large: '15px',
          desktop: '16px'
        } }}>
          We've sent a temporary login link.
          <br />
          Please check your inbox at <strong>{ email }</strong>
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Code *"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} 
          />

          <Button 
            type="submit" 
            variant="contained" 
            fullWidth 
            sx={{
              color: 'white', 
              backgroundColor: 'black', 
              borderRadius: '25px', 
              fontSize: {
                small: '8px',
                mobile: '8px',
                middle: '10px',
                tablet: '10px',
                large: '12px',
                desktop: '12px'
              },
              padding: '10px 15px',  
              '&:hover': {
                backgroundColor: 'lightgray',
                color: 'black',
              }
            }}
          >
            Continue with verification code
          </Button>
        </form>
      </Box>
    </Modal>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={() => setSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
    </>
    
  );
});

export default VerificationModal;
