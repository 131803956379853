import { Formik, Form, Field } from 'formik';
import { Button, TextField, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { inviteParticipant } from '../../redux/participantsSlice';
import { setElement } from '../../redux/settingsSlice';

export const fonts = {
    small: '12px',
    mobile: '12px',
    middle: '14px',
    tablet: '15px',
    large: '16px',
    desktop: '16px'
}

const AddParticipant = () => {
    
    const [allGroups, setAllGroups] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectOpen, setSelectOpen] = useState(false);

    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.auth.accessToken);
    const spaceId = useSelector(state => state.workspace.selectedSpace);

    useEffect(() => {
        const fetchGroupsAndRoles = async () => {
            try {
                const [groupsResponse, rolesResponse] = await Promise.all([
                    axios.get('/api/public/groups/all', {
                        headers: { Authorization: `Bearer ${accessToken}`, 'X-space-id': spaceId }
                    }),
                    axios.get('/api/public/users/roles/list', {
                        headers: { Authorization: `Bearer ${accessToken}`, 'X-space-id': spaceId }
                    })
                ]);
                setAllGroups(groupsResponse.data);
                setRoles(rolesResponse.data);
            } catch (error) {
                console.error("Error fetching groups or roles:", error);
            }
        };
        fetchGroupsAndRoles();
    }, [accessToken, spaceId]);

    return (
        <Box sx={{ width: { small: '90%', mobile: '90%', middle: '80%', tablet: '70%', large: '70%', desktop: '70%' } }}>
            <Formik
                initialValues={{ email: '', roleUuid: '', groups: [] }}
                onSubmit={async (values) => {
                    dispatch(inviteParticipant(accessToken, spaceId, values.email, values.groups, values.roleUuid)).then(() => {
                        dispatch(setElement('Participants'))
                    });
                }}>
                {({ setFieldValue, values }) => (
                    <Form>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Field
                                as={TextField}
                                name="email"
                                label="Email"
                                sx={{ width: '45%' }}
                                InputProps={{ sx: { fontSize: fonts } }}
                                InputLabelProps={{ sx: { fontSize: fonts  } }}
                            />
                            <FormControl fullWidth sx={{ width: '45%' }}>
                                <InputLabel sx={{ fontSize: fonts }}>Role</InputLabel>
                                <Select
                                    label="Role"
                                    value={values.roleUuid}
                                    onChange={(event) => setFieldValue("roleUuid", event.target.value)}
                                    renderValue={(selected) => 
                                        roles.find((role) => role.id === selected)?.name || "Select Role"
                                    }>
                                    {roles.map((role) => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        
                        <Box sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel sx={{ fontSize: fonts }}>Groups</InputLabel>
                                <Select
                                    label="Groups"
                                    multiple
                                    value={values.groups}
                                    open={selectOpen}
                                    onOpen={() => setSelectOpen(true)}
                                    onClose={() => setSelectOpen(false)}
                                    onChange={(event) => setFieldValue("groups", event.target.value)}
                                    renderValue={(selected) =>
                                        selected
                                            .map((id) => allGroups.find((group) => group.id === id)?.name)
                                            .filter(Boolean)
                                            .join(", ")
                                    }>
                                    {allGroups.map((group) => (
                                        <MenuItem key={group.id} value={group.id}>
                                            {group.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                            <Button
                                type="submit"
                                disabled={!values.email || !values.groups.length === 0 || !values.roleUuid}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'gray',
                                    '&:hover': { backgroundColor: 'black' },
                                    '&:active': { backgroundColor: 'black' },
                                    '&.Mui-disabled': { backgroundColor: 'lightgray', color: 'darkgray' }
                                }}>
                                Add
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default AddParticipant;