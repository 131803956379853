import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, TextField, Modal, } from '@mui/material';
import { closeWorkSpace, getCurrentSpace, setSelectedName, setSelectedSpace, setWorkSpace, setWorkSpaceId } from '../../redux/workspaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setClose } from '../../redux/settingsSlice';
import { useDebouncedCallback } from 'use-debounce';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import ConfirmTransfer from './ConfirmTransfer';
import { setOwnerId } from '../../redux/shortMemorySlice';

export const modalStyle = {
  width: {
    small: '90vw',
    mobile: '90vw',
    middle: '70vw',
    tablet: '50vw',
    large: '30vw',
    desktop: '30vw',
  },
  bgcolor: 'white',
  borderRadius: '8px',
  padding: 3,
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

const dangerButton = {
  color: 'red', backgroundColor: 'white', '&:hover': { color: 'white', backgroundColor: '#ff2d33' }, fontSize: {
    small: '9px',
        mobile: '9px',
        middle: '10px',
        tablet: '11px',
        large: '11px',
        desktop: '13px',
  }
}

const dangerBox = {
  display: 'flex', flexDirection: 'row', p: 2, alignItems: 'center', justifyContent: 'space-between' 
}

const dangerText = {
  color: 'gray',
                fontSize: {
                  small: '11px',
                  mobile: '11px',
                  middle: '12px',
                  tablet: '13px',
                  large: '13px',
                  desktop: '15px',
                },
                maxWidth: '65%',
}

export const miniModal = {
  display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2
}

const inputStyle = {
  border: '1px solid gray',
            borderRadius: '10px',
            pl: 2,
            mb: 2,
            width: '75%'
}

const input = {
  disableUnderline: true,
  sx: {
    fontSize: {
      small: '11px',
      mobile: '11px',
      middle: '12px',
      tablet: '13px',
      large: '13px',
      desktop: '15px',
    },
  },
}

const webSchema = Yup.object({
  website: Yup.string().url('Enter valid url').required('Website is required')
})

const spaceSchema = Yup.object({
  name: Yup.string().required('Space name is required')
})

const General = () => {

  const dispatch = useDispatch();
  const [openClose, setOpenClose] = useState(false);
  const [openTr, setOpenTr] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const accessToken = useSelector(state => state.auth.accessToken);
  const settingsItem = useSelector(state => state.settings.settingsItem);
  const [orgName, setOrgName] = useState(settingsItem.orgName);
  const [orgWebsite, setOrgWebsite] = useState(settingsItem.orgWebsite);
  const allSpaces = useSelector(state => state.workspace.workspaces);
  const workspaceId = useSelector(state => state.workspace.workspaceId);
  const user = useSelector(state => state.login.user);


  const changeWebsite = async (website) => {
    try {
      await axios.put(`/api/public/spaces/settings/edit`, {
        "orgName": orgName,
        "orgWebsite": website
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-space-id': settingsItem.id
        }
      });
    } catch (e) {
      console.error("Error changing website: ", e);
    }
  }

  const changeName = async (name) => {
    try {
      await axios.put(`/api/public/spaces/settings/edit`, {
        "orgName": name,
        "orgWebsite": orgWebsite
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-space-id': settingsItem.id
        }
      }).then(() => {
        if (settingsItem.id === workspaceId){
          dispatch(setSelectedName(name))
        } 
      })
    } catch (e){
      console.error("Error changing name: ", e);
    }
  } 

  useEffect(() => {
    dispatch(getCurrentSpace(accessToken, settingsItem.id, user.userId)).then(response => {
      setOrgName(response.data.orgName);
      setOrgWebsite(response.data.orgWebsite);
      if (response.data.ownerId === user.userId){
        setIsOwner(true);
        dispatch(setOwnerId(response.data.ownerId));
      }
    })
  }, []);

  const textValue = useDebouncedCallback((value) => {
        if (value !== settingsItem.orgName) {         
              changeName(value);
              dispatch(setWorkSpace(accessToken));
          }
  }, 1000);

  
  const siteValue = useDebouncedCallback((value) => {
    if (value !== settingsItem.orgSite) {
      changeWebsite(value);
      dispatch(setWorkSpace(accessToken))
    }
  }, 1000);

  const openCloseChange = () => {
    setOpenClose(true)
  }

  const openTrChange = () => {
    setOpenTr(true);
  };

  const closeClosChange = () => {
    setOpenClose(false);
  };

  const closeTrChange = () => {
    setOpenTr(false);
  };

  const handleConfirmClose = () => {
    dispatch(closeWorkSpace(accessToken, settingsItem.id)).then(async () => {
      await dispatch(setWorkSpace(accessToken)).then(() => {
        if (allSpaces.length > 1) {
          const remainingSpaces = allSpaces.filter(space => space.id !== settingsItem.id);
          const randomIndex = Math.floor(Math.random() * remainingSpaces.length);
          const newWorkspace = remainingSpaces[randomIndex];
          dispatch(setWorkSpaceId(newWorkspace.id));
          dispatch(setSelectedSpace(newWorkspace.id));
          dispatch(setSelectedName(newWorkspace.orgName));
        } else {
          dispatch(setWorkSpaceId(''));
          dispatch(setSelectedSpace(''));
          dispatch(setSelectedName(''));
        }
      });
    });
    setOpenClose(false);
    dispatch(setClose());
  };

  return (
    <Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{
        mb: 1, color: 'gray', ml: 1
      }}>Space name</Typography>
      <Formik
          initialValues={{ space: orgName || '' }}
          enableReinitialize
          onSubmit={() => {}}
          validationSchema={spaceSchema}
        >
          {({ setFieldValue, errors, touched }) => (
            <Form>
              <Field
                as={TextField}
                onChange={(e) => {
                  setFieldValue('space', e.target.value)
                  textValue(e.target.value)
                }}
                placeholder="Space name"
                variant="standard"
                InputProps={input}
                sx={inputStyle}
                name="space"
                error={touched.space && Boolean(errors.space)}
                helperText={touched.space && errors.space}
              />
            </Form>
          )}
        </Formik>
        <Formik
          initialValues={{ website: orgWebsite || '' }}
          enableReinitialize
          onSubmit={() => {}}
          validationSchema={webSchema}
        >
          {({ setFieldValue, errors, touched }) => (
            <Form>
            <Typography variant="h5" sx={{
              mb: -2, color: 'gray', ml: 1
            }}>Website</Typography>
              <Field
                as={TextField}
                onChange={(e) => {
                  setFieldValue('website', e.target.value);
                  siteValue(e.target.value, setFieldValue)
                }}
                placeholder="Website"
                variant="standard"
                InputProps={input}
                sx={{
                  ...inputStyle,
                  mt: 3,
                }}
                name="website"
                error={touched.website && Boolean(errors.website)}
                helperText={touched.website && errors.website}
              />
            </Form>
          )}
        </Formik>

        <Box
          sx={{
            width: '100%',
            borderRadius: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            mt: 3,
          }}
        >
          <Box sx={dangerBox}>
            <Typography
              sx={dangerText}
            >
              Removes current space. The process can not be undone.
            </Typography>
            <Button onClick={openCloseChange} sx={dangerButton} disabled={!isOwner}>
              Close space
            </Button>
          </Box>

          <Box sx={dangerBox}>
            <Typography
              sx={dangerText}
            >
              Transfers current ownership. The process can not be undone.
            </Typography>
            <Button onClick={openTrChange} sx={dangerButton} disabled={!isOwner}>
              Transfer ownership
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal open={openClose} onClose={closeClosChange}>
        <Box
          sx={modalStyle}
        >
          <Typography>Are you sure you want to delete the space?</Typography>
          <Box sx={miniModal}>
            <Button variant="confirmButton" sx={{
                '&:hover': {
                    backgroundColor: 'red',
                }
            }} onClick={handleConfirmClose}>
              Confirm
            </Button>
            <Button variant="confirmButton" sx={{
              '&:hover': {
                backgroundColor: 'black',
                color: 'white'
              }
            }} onClick={closeClosChange}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <ConfirmTransfer openTr={openTr} closeTrChange={closeTrChange} setOpenClose={setOpenClose} setIsOwner={setIsOwner}></ConfirmTransfer>
    </Box>
  );
};

export default General;
