import { Typography, Box, Link, Button, FormControl, TextField, Snackbar, Alert } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import CheckboxWithText from './CheckboxWithText.jsx';
import VerificationModal from './Verification.jsx';
import { setAuthToken, setRefreshToken, setAccessToken} from '../../redux/authSlice.js';
import validationSchema from '../../utils/validationSchema.js';
import { loggedIn } from '../../redux/loginSlice.js';
import axios from 'axios';
import { setAdmin } from '../../redux/loginSlice.js';
import { setExpiresIn } from '../../redux/authSlice.js';
import { startInterval } from '../../redux/authSlice.js';
import theme from '../../themeStyle.jsx';
import { clearStorage } from '../../store.js';

const forgotStyle = {
  color: 'gray',
  textDecorationColor: 'gray',
  fontSize: '12px',
  ml: 3,
  pb: 2
};

const Authentication = ({ isRegister, isLogin }) => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
  const handleClose = () => { setOpen(false) };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  let initialValues = {};

  if (isRegister) {
    initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatPassword: '',
      checkbox: false,
    };
  }
  if (isLogin) {
    initialValues = {
      email: '',
      password: '',
    };
  }

  const handleSubmit = async (values, { resetForm }) => {
      if (isRegister) {
        try {
          clearStorage();
          const response = await axios.post('/api/public/auth/sign-up', {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName
          });
          
          if (response.data.nextStep) {
            dispatch(setAuthToken(response.data.authSession));
            dispatch(loggedIn({ user: values, token: '' }));
            setOpen(true);
            showSnackbar('Verification email sent! Please check your email.', 'success');
          } 
        } catch (error) {
          console.error('Error during registration:', error.response ? error.response.data : error.message);
          showSnackbar('User with this email already exists. Please try different email', 'error');
        }
      }
    
      if (isLogin) {
        clearStorage();
        try {
          const props = {
            username: values.email,
            password: values.password,
          };
          const response = await axios.post('/api/public/auth/login', props, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          if (response.data.nextStep) {
            dispatch(setAuthToken(response.data.authSession));
            dispatch(loggedIn({ user: values, token: '' }));
            setOpen(true);
            showSnackbar('Verification email was sent! Please check your email.', 'success');
          } else if (response.data.access_token) {
            dispatch(setRefreshToken(response.data.refresh_token));
            dispatch(setAccessToken(response.data.access_token));
            dispatch(setExpiresIn(response.data.expires_in));
            dispatch(startInterval(response.data.refresh_token, response.data.expires_in));
            dispatch(loggedIn({ user: values }));
            navigate('/dashboard');
            if (values.email === 'admin@gmail.com'){
              dispatch(setAdmin());
            }
          }
        } catch (error) {
          console.error('Error during login:', error.response ? error.response.data : error.message);
          showSnackbar('Invalid email or password. Please try again', 'error');
        }
      }
    resetForm();
};

  useEffect(() => {
    return () => {
      setSnackbarOpen(false);
    };
  }, []);

  return (
    <>
      <Box sx={{ textAlign: 'center', p: 1 }}>
        <Typography>{isRegister ? 'Sign up' : 'Sign in'}</Typography>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(isRegister)}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, resetForm }) => (
          <Form style={{width: '95%'}}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                gap: '10px',
                width: '100%',
              }}
            >
              {isRegister && (
                <>
                  <FormControl>
                    <Field
                      as={TextField}
                      name="firstName"
                      label="First Name *"
                      variant="outlined"
                      fullWidth
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </FormControl>

                  <FormControl>
                    <Field
                      as={TextField}
                      name="lastName"
                      label="Last Name *"
                      variant="outlined"
                      fullWidth
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </FormControl>
                </>
              )}

              <FormControl>
                <Field
                  as={TextField}
                  name="email"
                  label="Email *"
                  variant="outlined"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </FormControl>

              <FormControl>
                <Field
                  as={TextField}
                  name="password"
                  type="password"
                  label="Password *"
                  variant="outlined"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </FormControl>

              {isLogin && (
                <Link
                  onClick={() => {
                    console.log('Redirecting to password reset page');
                  }}
                  sx={forgotStyle}
                >
                  Forgot Your Password?
                </Link>
              )}

              {isRegister && (
                <>
                  <FormControl>
                    <Field
                      as={TextField}
                      name="repeatPassword"
                      type="password"
                      label="Repeat Password *"
                      variant="outlined"
                      fullWidth
                      error={touched.repeatPassword && Boolean(errors.repeatPassword)}
                      helperText={touched.repeatPassword && errors.repeatPassword}
                    />
                  </FormControl>

                <FormControl>
                    <Field
                        type="checkbox"
                        name="checkbox"
                        component={CheckboxWithText}
                    />
                    <ErrorMessage name="checkbox" component="div" style={{ color: 'red' }} />
                </FormControl>
                </>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                gap: '15px',
              }}
            >
              <Button type="submit" variant="authButton" sx={{ mt: '5px' }}>
                {isLogin ? 'Sign in' : 'Sign up'}
              </Button>

              {isLogin && <Button variant="authButton">Continue with GMAIL</Button>}
            </Box>
          </Form>
        )}
      </Formik>

      <Box sx={{ textAlign: 'center', pt: '10px' }}>
        {isLogin ? (
          <Typography sx={{ margin: 'auto', mb: 1, color: 'gray' }}>
            Don't have an account?{' '}
            <Link
              onClick={() => {
                navigate('/registration');
              }}
              style={{
                color: 'black',
                textDecorationColor: 'black',
                cursor: 'pointer',
              }}
            >
              Sign up
            </Link>
          </Typography>
        ) : (
          <Typography sx={{ margin: 'auto', color: 'gray' }}>
            Already have an account?{' '}
            <Link
              onClick={() => {
                navigate('/login');
              }}
              style={{
                color: 'black',
                textDecorationColor: 'black',
                cursor: 'pointer',
              }}
            >
              Sign In
            </Link>
          </Typography>
        )}

        {isLogin && (
          <Typography sx={{ textAlign: 'center', color: 'gray', width: '90%', margin: 'auto', fontSize: {
            small: '12px',
            mobile: '12px',
            middle: '14px',
          } }}>
            By signing up you agree to our terms of Service and Data Processing Policy
          </Typography>
        )}
      </Box>

      {isLogin && (
        <VerificationModal
          open={open}
          onClose={() => {
            handleClose();
          }}
          email={initialValues.email}
          isLogin={true}
          setOpen={setOpen}
        ></VerificationModal>
      )}
      {isRegister && (
        <VerificationModal
          open={open}
          onClose={() => {
            handleClose();
          }}
          isRegistration={true}
        ></VerificationModal>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Authentication;
