/* eslint-disable */
import Authentication from "../Authentication/Authentication";
import { Container } from "@mui/material";

const LoginForm = () => {

    return (    
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 8,
                width: {
                    small: 300,
                    mobile: 300,
                    middle: 400,
                    tablet: 450,
                    large: 450,
                    desktop: 500,
                },
                justifyContent: 'space-around',
                alignItems: 'center',
                borderRadius: '20px',
                padding: '15px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)', 
            }}>
                <Authentication 
                isRegister={false} 
                isLogin={true} 
                onRegister={() => {console.log("This is register")}}
                onLogin={() => {console.log("This is login")}} ></Authentication>
            </Container>
    )
}

export default LoginForm;