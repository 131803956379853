import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    participants: []
}

const participantsSlice = createSlice({
    name: 'participants',
    initialState,
    reducers: {
        setParticipantsReducer: (state, action) => {
            state.participants = action.payload;
        },
        addParticipantReducer: (state, action) => {
            return {
                ...state,
                participants: [...state.participants, action.payload]
            }
        },
        deleteParticipantReducer: (state, action) => {
            return {
                ...state,
                participants: [...state.participants.filter(participant => participant.id !== action.payload)]
            }
        },
        resetParticipants: () => initialState,
    }
})

export const setParticipants = (accessToken, spaceId) => async (dispatch) => {

    if (spaceId && accessToken){
        await axios.get('/api/public/spaces/settings/participants', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-space-id': spaceId,
            }
        }).then(response => {
            console.log(response.data);
            dispatch(setParticipantsReducer(response.data))
        });
    }
}

export const inviteParticipant = (accessToken, spaceId, email, groups, roleUuid) => async (dispatch) => {
   if (email && spaceId){
    await axios.post(`/api/public/spaces/settings/participants/add`, {
        email: email,
        groups: groups,
        roleUuid: roleUuid 
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'X-space-id': spaceId,
        }
    }).then(() => {});
   }
}

export const transferOwnership = (accessToken, spaceId, futureOwnerUuid) => async (dispatch) => {
    if (accessToken && spaceId){
        console.log(accessToken)
        try {
            await axios.post(`/api/public/spaces/settings/transfer-ownership`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-space-id': spaceId,
                },
                params: {
                    futureOwnerUuid: futureOwnerUuid
                }
            });
        } catch (e){
            console.error("Error transferring ownership: ", e);
        }
    }
}

export const removeParticipant = (accessToken, spaceId, userId) => async (dispatch) => {
    if (accessToken && spaceId && userId){
        try {
            await axios.delete(`/api/public/spaces/settings/participants/${userId}/remove`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-space-id': spaceId,
                }
            }).then(() => {})
        } catch (e){
            console.error("Error removing participant: ", e);
        }
    }
}

export const { addParticipantReducer, setParticipantsReducer, resetParticipants, deleteParticipantReducer } = participantsSlice.actions;
export default participantsSlice.reducer;