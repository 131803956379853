import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f5f5f5',
    },
    info: {
      main: 'rgba(180, 163, 231, 1)'
    }
  },
  components: {
    MuiSelect: {
      variants: [
        {
          props: { variant: 'tableSelect' },
          style: {
            border: 'none',
            backgroundColor: 'transparent',
            padding: 0,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiSelect-icon': {
              color: 'black',
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        { props: {
          variant: 'rectangleSelect',
        }, style: {
          borderRadius: 0, 

        }}
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
      variants: [
        {
          props: { variant: 'authButton' },
          style: {
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '20px',
            fontWeight: 'bold',
            padding: '10px 0',
            width: '100%',
            height: '40px',
            fontWeight: 400,
            cursor: 'pointer',
            fontSize: {
              small: '9px',
              mobile: '9px',
              middle: '10px',
              tablet: '11px',
              large: '11px',
              desktop: '13px',
            },
            '&:hover': {
              color: '#33beff',
            },
          },
        },
        {
          props: { variant: 'addButton'},
          style: {
            color: 'white',
            backgroundColor: 'gray',
            borderRadius: '15px',
            '&:hover': {
              backgroundColor: 'lightgray'
            },
          }
        },
        {
          props: { variant: 'confirmButton'},
          style: {
            color: 'white',
            backgroundColor: 'gray',
            borderRadius: '15px',
            '&:hover': {
              backgroundColor: 'red'
            },
            width: '30%',
            fontSize: {
              small: '9px',
              mobile: '9px',
              middle: '10px',
              tablet: '11px',
              large: '11px',
              desktop: '13px',
            }
          }
        },
        {
          props: { variant: "stButton"},
          style: {
            backgroundColor: 'gray',
            color: 'white',
            fontSize: {
              small: '10px',
              mobile: '10px',
              middle: '11px',
              tablet: '12px',
              large: '12px',
              desktop: '13px',
            },
            '&:hover': {
              backgroundColor :'lightgray'
            },
            width: '50%',
            ml: 'auto', mr: 'auto'
          }
        },
        {
          props: { variant: "grButton" },
          style: {
            backgroundColor: 'gray',
            color: 'white',
            '&:hover': {
              backgroundColor :'lightgray'
            },
            display: 'inline-block',
            
          }
        }
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          height: 50,
          '& fieldset': {
            height: 50,
            borderRadius: 50,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '&.Mui-selected': {
            backgroundColor: 'white',
            color: 'lightgray',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxWidth: '250px',
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  breakpoints: {
    values: {
      small: 0,
      mobile: 320,
      middle: 500,
      tablet: 768,
      large: 900,
      desktop: 1024,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h4: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1.25rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.5rem',
      },
    },
    h5: {
      fontSize: '0.75rem',
      '@media (min-width:600px)': {
        fontSize: '0.75rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.25rem',
      },
    },
    h6: {
      fontSize: '0.625rem',
      '@media (min-width:600px)': {
        fontSize: '0.625rem',
      },
      '@media (min-width:900px)': {
        fontSize: '0.75rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1rem',
      },  
    },
    little: {
      fontSize: '0.375rem',
      '@media (min-width:600px)': {
        fontSize: '0.375rem',
      },
      '@media (min-width:900px)': {
        fontSize: '0.5rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '0.625rem',
      },
    },
    body2: {
      fontSize: '0.75rem',
      '@media (min-width:600px)': {
        fontSize: '0.875rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.125rem',
      },
    },
  },
});


export default theme;