import React from 'react';
import { FormControlLabel, Checkbox, Typography, Link } from '@mui/material';
import PropTypes from 'prop-types';

const CheckboxWithText = ({ field, form }) => {
  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.checked);
  };

  const linkStyle = {
    textDecorationColor: 'gray',
    color: 'gray',
  };

  return (
    <FormControlLabel
      sx={{ width: '75%', pb: 1}}
      control={
        <Checkbox
          {...field}
          checked={field.value}
          onChange={handleChange}
          color="primary"
        />
      }
      label={
        <Typography sx={{ color: 'gray', display: 'inline', 
          fontSize: {
          desktop: '15px',
          large: '15px',
          tablet: '13px',
          middle: '13px',
          mobile: '11px',
          small: '11px'
        } }}>
          I agree with the&nbsp;
          <Link sx={linkStyle}>offer agreement</Link>
          &nbsp;and processing of&nbsp;
          <Link sx={linkStyle}>personal data</Link>
        </Typography>
      }
      labelPlacement="end"
    />
  );
};

CheckboxWithText.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default CheckboxWithText;
