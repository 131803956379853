import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Select, MenuItem, Checkbox, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setParticipants, transferOwnership } from '../../redux/participantsSlice';
import { modalStyle, miniModal } from './General';

const ConfirmTransfer = ({ openTr, closeTrChange, setOpenClose, setIsOwner}) => {
    const [checked, setChecked] = useState(false);
    const [chosen, setChosen] = useState(null); 
    const dispatch = useDispatch();

    const workspaceId = useSelector(state => state.workspace.selectedSpace);
    const accessToken = useSelector(state => state.auth.accessToken);
    const participants = useSelector(state => state.participants.participants);
    const user = useSelector(state => state.login.user);

    useEffect(() => {
        if (openTr){
            dispatch(setParticipants(accessToken, workspaceId))
        }
    }, [accessToken, workspaceId, dispatch]);

    const handleConfirmTransfer = () => {
        if (checked && chosen) {
            dispatch(transferOwnership(accessToken, workspaceId, chosen.spaceParticipantDto.userId));
            setOpenClose(false);
            setIsOwner(false);
            closeTrChange();
        }
    };

    return (
        <Modal open={openTr} onClose={closeTrChange}         >
            <Box sx={{...modalStyle, width: {
                small: '75vw',
                mobile: '75vw',
                middle: '60vw',
                tablet: '50vw',
                large: '40vw',
                desktop: '40vw'
            }}} >
                <Typography>Do you want to proceed with the transfer?</Typography>
                <Box mt={2} sx={{ display: 'flex', flexDirection: 'row'}}>
                    {participants.length > 0 && (
                        <Select
                            value={chosen ? chosen.spaceParticipantDto.userId : ''}
                            onChange={(event) => {
                                const selectedId = event.target.value;
                                const selectedParticipant = participants.find(
                                    (p) => p.spaceParticipantDto.userId === selectedId
                                );
                                setChosen(selectedParticipant);
                            }}
                            displayEmpty
                            renderValue={(value) =>
                                chosen
                                    ? `${chosen.spaceParticipantDto.firstName} ${chosen.spaceParticipantDto.lastName}`
                                    : 'Select a participant'
                            }
                        >
                            {participants
                            .filter(
                                    (participant) => participant.spaceParticipantDto.userId !== user.userId
                                )
                                .map((participant, index) => (
                                    <MenuItem
                                        key={index}
                                        value={participant.spaceParticipantDto.userId}
                                    >
                                        {`${participant.spaceParticipantDto.firstName} ${participant.spaceParticipantDto.lastName}`}
                                    </MenuItem>
                                ))}
                        </Select>
                    )}

                    <Checkbox
                        color="success"
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        onChange={(event) => setChecked(event.target.checked)}
                        checked={checked}
                    />
                </Box>
                <Box sx={{...miniModal, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
                }} mt={2}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: checked && chosen ? '#ff2d33' : 'gray',
                            '&:hover': { backgroundColor: checked && chosen ? '#ff2d33' : 'gray' }
                        }}
                        disabled={!checked || !chosen}
                        onClick={handleConfirmTransfer}
                    >
                        Confirm
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'black',
                            color: 'white',
                            '&:hover': { backgroundColor: 'black', color: 'white' }
                        }}
                        onClick={closeTrChange}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmTransfer;
