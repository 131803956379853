import { Box, FormControl, IconButton, TextField, Typography, ListSubheader, Card, InputLabel, CardContent, Divider, List, ListItem, Button, OutlinedInput, Stack, Chip, ListItemText, Select, Checkbox, MenuItem } from '@mui/material';
import { Edit, OutlinedFlagTwoTone } from '@mui/icons-material';
import Skype from '../../assets/svg/Skype.jsx';
import Slack from '../../assets/svg/Slack.jsx';
import { Telegram, WhatsApp, LinkedIn } from '@mui/icons-material';
import AvatarWithPlus from './Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { updateUserData } from '../../redux/loginSlice.js';
import { useEffect, useState } from 'react';
import CardTabs from './CardTabs.jsx';
import React, {memo} from 'react';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Skills from './Skills.jsx';

const cardStyle = {
  borderRadius: '20px',
  width: '100%',
  maxWidth: '280px',
  maxHeight: '270px'
};

const button = {
  color: 'black',
  backgroundColor: 'lightgray',
  borderRadius: '15px'
}

const inputProps = {
  maxHeight: '20px', maxWidth: {small: '30px', mobile: '30px', middle: '70px', tablet: '70px', large: '90px', desktop: '90px'}
}

const formControl = {
  marginTop: '-10px', pl: {mobile: 1, small: 1, middle: 2}
}

// opening - select is opened automatically 
// not opening - hidden 
//
//

const FullProfile = memo(() => {
  
  const [saving, setSaving] = useState(false);
  const user = useSelector(state => state.login.user);
  const accessToken = useSelector(state => state.auth.accessToken);
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState('info');
  const [skillsSelect, setSkillsSelect] = useState(false);
  const [formattedDate, setFormattedDate] = useState(new Date(user.workingStartDate).toLocaleDateString('en-GB'));
  const skills = useSelector(state => state.technologies.technologies);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [add, setAdd] = useState(false);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    setFormattedDate(new Date(user.workingStartDate).toLocaleDateString('en-GB'));
  }, [user.workingStartDate]);

  const formatToISO8601 = (date) => {
    const isoDate = new Date(date).toISOString().split('.')[0];
    const microseconds = '000000';
    return `${isoDate}.${microseconds}Z`;
  };

  return (
    <Formik
      initialValues={{
        firstName: user.firstName || '',
        middleName: user.middleName || '',
        lastName: user.lastName || '',
        contactEmail: user.contactEmail || '',
        contactPhone: user.contactPhone || '',
        workingStartDate: formattedDate || '',
        timeZone: user.timeZone || '',
        location: user.location || '',
        linkedin: user.linkedin || '',
        skype: user.skype || '',
        slack: user.slack || '',
        telegram: user.telegram || '',
        whatsapp: user.whatsapp || '',
        generalExpInYears: user.generalExpInYears || '',
      }}
      
      enableReinitialize
      onSubmit={(values, { resetForm }) => {
        const date = values.workingStartDate;
        const [day, month, year] = date.split('/').map(Number);
        const newDate = new Date(year, month - 1, day);
        const formattedWorkingStartDate = formatToISO8601(newDate);
        const formattedNow = formatToISO8601(new Date());

        dispatch(updateUserData(accessToken, {
          userId: user.userId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          contactEmail: values.contactEmail,
          contactPhone: values.contactPhone,
          workingStartDate: formattedWorkingStartDate,
          generalExpInYears: values.generalExpInYears,
          timeZone: values.timeZone,
          location: values.location,
          skype: values.skype,
          linkedin: values.linkedin,
          telegram: values.telegram,
          whatsapp: values.whatsapp,
          slack: values.slack,
          createdAt: formattedNow,
          updatedAt: formattedNow,
        }));

        setSaving(false);
        resetForm();
      }}
    >
      {({ values, errors, touched, resetForm }) => (
        <Form>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '16px',
              backgroundColor: '#e0e3e3',
              borderRadius: '20px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <AvatarWithPlus />
              {saving ? (
                <>
                  <FormControl sx={formControl}>
                    <Field 
                      InputProps={{ sx: inputProps }}
                      InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                      label={values.firstName ? ' ' : 'First name'}
                      as={TextField}
                      name="firstName"
                      variant="standard"
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </FormControl>
                  <FormControl sx={formControl}>
                    <Field
                      InputProps={{ sx: inputProps }}
                      InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                      label={values.middleName ? ' ' : 'Middle name'}
                      as={TextField}
                      name="middleName"
                      variant="standard"
                      error={touched.middleName && Boolean(errors.middleName)}
                      helperText={touched.middleName && errors.middleName}
                    />
                  </FormControl>
                  <FormControl sx={formControl}>
                    <Field
                      InputProps={{ sx: inputProps }}
                      InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                      label={values.lastName ? ' ' : 'Last name'}
                      as={TextField}
                      name="lastName"
                      variant="standard"
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </FormControl>
                </>
              ) : (
                <Typography variant="h5" sx={{ ml: 2 }}>
                  {user.firstName} {user.middleName} {user.lastName}
                </Typography>
              )}
            </Box>
            {!saving && <IconButton
              color="gray"
              onClick={() => {
                setSaving(!saving);
              }}
            >
              <Edit />
            </IconButton>}
          </Box>

              <CardTabs setTabs={setTabs}></CardTabs>

            {tabs === 'technologies' && <Skills add={add} setAdd={setAdd}></Skills>}

            {tabs === 'info' && <>
              <Box sx={{ display: 'flex', flexDirection: { small: 'column', mobile: 'column', tablet: 'row', large: 'row', desktop: 'row' }, gap: '20px', width: '100%', mt: 3 }}>
            <Card sx={cardStyle}>
              <CardContent>
                <Typography sx={{ fontSize: 'body2.fontSize' }} color="textSecondary" gutterBottom>
                  Contact Email
                </Typography>
                {saving ? (
                  <FormControl sx={{ marginTop: '-10px' }}>
                    <Field
                      InputProps={{ sx: { maxHeight: '20px', fontSize: '12px' } }}
                      InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                      label={values.contactEmail ? ' ' : 'Email'}
                      as={TextField}
                      name="contactEmail"
                      variant="standard"
                      error={touched.contactEmail && Boolean(errors.contactEmail)}
                      helperText={touched.contactEmail && errors.contactEmail}
                    />
                  </FormControl>
                ) : (
                  <Typography variant="h5" component="div">
                    {user.contactEmail}
                  </Typography>
                )}
                <Divider sx={{ margin: '16px 0' }} />
                <Typography sx={{ fontSize: 'body2.fontSize' }} color="textSecondary" gutterBottom>
                  Contact Phone
                </Typography>
                {saving ? (
                  <FormControl sx={{ marginTop: '-10px' }}>
                    <Field
                      InputProps={{ sx: { maxHeight: '20px', fontSize: '12px' } }}
                      InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                      label={values.contactPhone ? ' ' : 'Phone'}
                      as={TextField}
                      name="contactPhone"
                      variant="standard"
                      error={touched.contactPhone && Boolean(errors.contactPhone)}
                      helperText={touched.contactPhone && errors.contactPhone}
                    />
                  </FormControl>
                ) : (
                  <Typography variant="h5" component="div">
                    {user.contactPhone || '-'}
                  </Typography>
                )}
                <Divider sx={{ margin: '16px 0' }} />
                <Typography sx={{ fontSize: 'body2.fontSize' }} color="textSecondary" gutterBottom>
                  Time Zone / Location
                </Typography>
                {saving ? (
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <FormControl sx={{ width: '30%', marginTop: '-10px' }}>
                      <Field
                        InputProps={{ sx: { maxHeight: '20px', fontSize: '12px' } }}
                        InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                        label={values.timeZone ? ' ' : 'Time zone'}
                        as={TextField}
                        name="timeZone"
                        variant="standard"
                        error={touched.timeZone && Boolean(errors.timeZone)}
                        helperText={touched.timeZone && errors.timeZone}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '40%', pl: 2, marginTop: '-10px' }}>
                      <Field
                        InputProps={{ sx: { maxHeight: '20px', fontSize: '12px' } }}
                        InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                        label={values.location ? ' ' : 'Location'}
                        as={TextField}
                        name="location"
                        variant="standard"
                        error={touched.location && Boolean(errors.location)}
                        helperText={touched.location && errors.location}
                      />
                    </FormControl>
                  </Box>
                ) : (
                  <Typography variant="h5" component="div">
                    {user.timeZone || '-'} {user.location}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card sx={cardStyle}>
              <CardContent>
                <Typography sx={{ fontSize: 12 }} color="textSecondary" gutterBottom>
                  Social Media
                </Typography>
                <List sx={{ overflow: 'auto' }}>
                  {[
                    { Icon: LinkedIn, label: user.linkedin, name: "linkedin" },
                    { Icon: Skype, label: user.skype, name: "skype" },
                    { Icon: Slack, label: user.slack, name: "slack" },
                    { Icon: Telegram, label: user.telegram, name: "telegram" },
                    { Icon: WhatsApp, label: user.whatsapp, name: "whatsapp" },
                  ].map(({ Icon, label, name }) => (
                    <ListItem key={name} sx={{ py: 0.5 }}>
                      <IconButton color="primary" sx={{ width: 32, height: 32 }}>
                        <Icon width={24} height={24} />
                      </IconButton>
                      {saving ? (
                        <FormControl sx={{ marginTop: '-10px' }}>
                          <Field
                            InputProps={{ sx: { maxHeight: '20px', fontSize: '12px' } }}
                            InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                            label={values[name] ? ' ' : name}
                            as={TextField}
                            name={name}
                            variant="standard"
                            error={touched[name] && Boolean(errors[name])}
                            helperText={touched[name] && errors[name]}
                          />
                        </FormControl>
                      ) : (
                        <Typography variant="h6" sx={{ ml: 1, fontSize: 14, textDecoration: 'underline' }}>
                          {label || ''}
                        </Typography>
                      )}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
            <Card sx={{ ...cardStyle, maxHeight: '200px' }}>
              <CardContent>
                <Typography sx={{ fontSize: 'body2.fontSize' }} color="textSecondary" gutterBottom>
                  Working Start Date
                </Typography>
                {saving ? (
                  <FormControl sx={{ marginTop: '-10px' }}>
                    <Field
                      InputProps={{ sx: { maxHeight: '20px', fontSize: '12px' } }}
                      InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                      label={values.workingStartDate ? ' ' : 'Start date'}
                      as={TextField}
                      name="workingStartDate"
                      variant="standard"
                      error={touched.workingStartDate && Boolean(errors.workingStartDate)}
                      helperText={touched.workingStartDate && errors.workingStartDate}
                    />
                  </FormControl>
                ) : (
                  <Typography variant="h5" component="div">
                    {formattedDate || '-'}
                  </Typography>
                )}
                <Divider sx={{ margin: '16px 0' }} />
                <Typography sx={{ fontSize: 'body2.fontSize' }} color="textSecondary" gutterBottom>
                  General Experience in Years
                </Typography>
                {saving ? (
                  <FormControl sx={{ marginTop: '-10px' }}>
                    <Field
                      InputProps={{ sx: { maxHeight: '20px', fontSize: '12px' } }}
                      InputLabelProps={{ sx: { fontSize: '11px' }, shrink: false }}
                      label={values.generalExpInYears ? ' ' : 'Experience'}
                      as={TextField}
                      name="generalExpInYears"
                      variant="standard"
                      error={touched.generalExpInYears && Boolean(errors.generalExpInYears)}
                      helperText={touched.generalExpInYears && errors.generalExpInYears}
                    />
                  </FormControl>
                ) : (
                  <Typography variant="h5" component="div">
                    {user.generalExpInYears || '-'}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>

         
          <Box
          sx={{ mt: 2, mb: 3, maxWidth: '200px', maxHeight: '70px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {!saving && (
            <>
              <IconButton onClick={() => setSaving(true)}>
                <Edit />
              </IconButton>
              <Typography variant="h5" component="div" sx={{ mt: '5px' }} onClick={() => setSaving(true)}>
                Edit Information
              </Typography>
            </>
          )}
          {saving && (
            <>
              <Button type="submit" sx={button}>Save</Button>
              <Button onClick={() => {
                setSaving(false);
                resetForm();
              }} sx={{...button, ml: 3}}>Cancel</Button>
            </>
          )}
        </Box>
            </>}
        </Form>
      )}
    </Formik>
  );
})

export default FullProfile;
