import {Typography,  Box, IconButton, MenuItem, Menu} from '@mui/material'
import { memo, useState, useEffect} from 'react';
import QuibenchLogo from '../../assets/svg/QuibenchLogo';
import MiniQuibenchLogo from '../../assets/svg/MiniQuibenchLogo';
import { useNavigate } from 'react-router-dom';
import { ArroRight } from '../../assets/svg/ArrowRight';
import MenuIcon from '@mui/icons-material/Menu';

const headerOption = {
    backgroundColor: 'rgba(225, 217, 243, 1)',
    fontWeight: 700,
    borderRadius: '25px',
    padding: '5px 25px',
    '&:hover': {
        cursor: 'pointer'
    },
    fontFamily: '"Manrope", sans-serif'
}

export const Request = ({ fit, fullWidth = false }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: fullWidth ? '100%' : (fit ? 'fit-content' : '100%'),
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: {
                    desktop: '8px 24px',
                    large: '8px 24px',
                    tablet: '7px 22px',
                    middle: '6px 20px',
                    small: '5px 14px',
                    mobile: '5px 14px'
                },
                fontWeight: '500',
                backgroundColor: 'rgba(183, 160, 231, 1)',
                borderRadius: '20px',
                '&:hover': { cursor: 'pointer' },
                color: 'black',
                boxSizing: 'border-box'
            }}
        >
            <Typography
                sx={{
                    fontFamily: '"Manrope", sans-serif',
                    paddingRight: {
                        small: '5px',
                        mobile: '5px',
                        middle: '6px',
                        tablet: '7px',
                        large: '8px',
                        desktop: '8px'
                    },
                    fontWeight: 600,
                    fontSize: {
                        small: '11px',
                        mobile: '11px',
                        middle: '12x',
                        tablet: '13px',
                        large: '14px',
                        desktop: '14px'
                    },
                }}
            >
                BOOK A DEMO
            </Typography>
            <ArroRight />
        </Box>
    );
};


const DefaultHeader = memo(() => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleScrollToForm = () => {
        window.scrollTo({
            top: 4065,
            behavior: 'smooth'
        });
    };

    const [chosenOption, setChosenOption] = useState('product');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getPadding = () => {
        if (windowWidth < 400) {
            return '16px';
        } else if (windowWidth < 800) {
            return '40px';
        } else if (windowWidth < 1200) {
            return '64px';
        } else return '96px';
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center',
            maxWidth: '100%',
            backgroundColor: 'rgba(36, 33, 43, 1)',
            pt: '48px',
            paddingLeft: getPadding(),
            paddingRight: getPadding(),
            mb: '-2px',
            position: 'relative',
            justifyContent: 'space-between'
        }}>
            <Box sx={{ '&:hover': { cursor: 'pointer' } }}>
                {windowWidth < 750 ? <MiniQuibenchLogo /> : <QuibenchLogo />}
            </Box>
            { windowWidth >= 1200 && (<>
                <Box sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'white',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row', 
                    alignItems: 'center',
                }}>
                    {['product', 'pricing', 'resources'].map(option => (
                        <Typography
                            key={option}
                            onClick={() => setChosenOption(option)}
                            sx={chosenOption === option ? headerOption : { 
                                margin: '0 35px', 
                                '&:hover': { cursor: 'pointer' }, 
                                fontFamily: '"Manrope", sans-serif' 
                            }}
                        >
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                        </Typography>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Typography
                        sx={{ 
                            color: 'white', 
                            fontSize: '16px', 
                            fontFamily: '"Manrope", sans-serif', 
                            marginRight: '40px', 
                            '&:hover': { cursor: 'pointer' } 
                        }}
                        onClick={() => navigate('/login')}
                    >
                        Sign in
                    </Typography>
                    <Box onClick={() => {
                        handleScrollToForm();
                    }}>
                        <Request fit={true} />
                    </Box>
                </Box>
            </>) }
            { windowWidth < 1200 && windowWidth >= 750 && (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '40px'}}>
                    <Box onClick={() => {
                        handleScrollToForm();
                    }}>
                        <Request fit={true} />
                    </Box>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        sx={{ padding: '5px' }}>
                        <MenuIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{ 'aria-labelledby': 'long-button' }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}>
                        {['product', 'pricing', 'resources'].map(option => (
                            <MenuItem
                                key={option}
                                selected={chosenOption === option}
                                onClick={() => {
                                    setChosenOption(option);
                                    handleClose();
                                }}
                            >
                                {option.charAt(0).toUpperCase() + option.slice(1)}
                            </MenuItem>
                        ))}
                        <MenuItem key={"sign-in"} sx={{ textAlign: 'center', color: 'rgba(183, 160, 231, 1)', fontWeight: 600}} onClick={() => {
                            handleClose();
                            navigate('/login')
                        }}>Sign In</MenuItem>
                    </Menu>
                </Box>
            )}
            { windowWidth < 750 && (<>
                <Box onClick={() => {
                        handleScrollToForm();
                    }}>
                        <Request fit={true} />
                    </Box>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        sx={{ padding: '5px' }}
                    >
                        <MenuIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                            sx: { padding: 0 }
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={{
                            '& .MuiPaper-root': {
                                borderBottomLeftRadius: '20px',
                                borderBottomRightRadius: '20px',
                                padding: 0
                            },
                        }}
                    >
                        {['product', 'pricing', 'resources'].map(option => (
                            <MenuItem
                                key={option}
                                selected={chosenOption === option}
                                onClick={() => {
                                    setChosenOption(option);
                                    handleClose();
                                }}
                            >
                                {option.charAt(0).toUpperCase() + option.slice(1)}
                            </MenuItem>
                        ))}
                        <MenuItem
                            key="sign-in"
                            sx={{

                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(183, 160, 231, 1)',
                                color: 'black', 
                                fontWeight: 600,
                                borderRadius: '15px',
                                mb: '0px',
                                '&:hover': {
                                    backgroundColor: 'rgba(153, 130, 201, 1)',
                                },
                            }}
                            onClick={() => {
                                handleClose();
                                navigate('/login');
                            }}
                        >
                            Sign In
                        </MenuItem>
                    </Menu>

            </>)}
        </Box>
    );
});


export default DefaultHeader;
