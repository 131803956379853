import React, { memo, useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { buttonStyle } from './ProfileButton';
import { setWorkSpaceId } from '../../redux/workspaceSlice';
import { setSelectedSpace, setSelectedName, setSelectedSite } from '../../redux/workspaceSlice';
import { setSettingsItem, setOpen } from '../../redux/settingsSlice';

const button = {
    borderRadius: '25px',
    mb: '7px',
    width: 'fit-content',
    color: 'black',
    '&:hover': { backgroundColor: 'lightgray' },
    fontSize: {
        small: '8px',
        mobile: '8px',
        middle: '10px',
        tablet: '10px',
        large: '12px',
        desktop: '12px',
    }
}

const Spaces = memo(() => {

    const selectedWorkspaceId = useSelector(state => state.workspace.selectedSpace);
    const selectedName = useSelector(state => state.workspace.selectedName);
    const workspaces = useSelector(state => state.workspace.workspaces);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (!selectedWorkspaceId && workspaces.length > 0){
    //         console.log(" work")
    //         const randomInt = Math.floor((Math.random() * workspaces.length));
    //         console.log(workspaces[randomInt]);
    //         dispatch(setWorkSpaceId(workspaces[randomInt].id));
    //         dispatch(setSelectedName(workspaces[randomInt].orgName));
    //         dispatch(setSelectedSpace(workspaces[randomInt].orgName));
    //         dispatch(setSelectedSite(workspaces[randomInt].orgWebsite));
    //     }
    // }, []);
    
    return (
        <>
            <Box sx={{ borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '10px 0', width: '100%', margin: '10px 0' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                    <Typography sx={buttonStyle}>Workspaces</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}></Box>
                {workspaces.length === 0 ? (
                    <Button onClick={() => navigate('/createWorkspace')} sx={button}>
                        + Create Workspace
                    </Button>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {workspaces.map((item, id) => (
                            <Box key={id}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography
                                onClick={() => {
                                    if (selectedWorkspaceId !== item.id) {
                                        dispatch(setWorkSpaceId(item.id));
                                        dispatch(setSelectedSpace(item.id));
                                        dispatch(setSelectedName(item.orgName))
                                        dispatch(setSelectedSite(item.orgSite));
                                    }
                                }}
                                sx={{
                                    ...button,
                                    backgroundColor: selectedWorkspaceId === item.id ? 'lightgray' : 'transparent',
                                  }}>
                                {item.orgName}
                            </Typography>
                             <IconButton color="gray" sx={{ cursor: 'pointer', maxWidth: '15px', maxHeight: '15px' }} 
                                onClick={() => {
                                    dispatch(setSettingsItem(item));
                                    dispatch(setWorkSpaceId(item.id));
                                    dispatch(setSelectedName(item.orgName));
                                    dispatch(setSelectedSpace(item.id));
                                    dispatch(setSelectedSite(item.orgWebsite));
                                    dispatch(setOpen());
                                }}>
                                <SettingsIcon />
                            </IconButton>
                            </Box>
                            </Box>
                        ))}
                        <Button onClick={() => navigate('/createWorkspace')} sx={{ ...button }}>
                        + Create Workspace
                    </Button>
                    </Box>
                )}
            </Box>
        </>
    );
})

export default Spaces;
