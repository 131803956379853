import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Menu, MenuItem, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch } from 'react-redux';
import { setSelectedGroupReducer, setGroupIdReducer } from '../../redux/groupSlice';
import { memo } from 'react';
import { setGroups } from '../../redux/groupSlice';

const Dropdown = memo(() => {

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const accessToken = useSelector(state => state.auth.accessToken);
  const teams = useSelector(state => state.group.groups);
  const team = useSelector(state => state.group.selectedGroup);
  const open = Boolean(anchorEl);
  const isAdmin = useSelector(state => state.login.isAdmin);
  const workspace = useSelector(state => state.workspace.selectedSpace);
  const workspaceId = useSelector(state => state.workspace.selectedSpace);
  
  const handleChange = (item) => {
    dispatch(setSelectedGroupReducer(item.name));
    dispatch(setGroupIdReducer(item.id));
  };
  
  useEffect(() => {
    if (workspaceId && workspaceId !== ''){
      dispatch(setGroups(accessToken, workspaceId)) }
  }, [workspaceId]); 

  useEffect(() => {
    if (teams.length > 0){
      dispatch(setGroupIdReducer(teams[0].id));
      dispatch(setSelectedGroupReducer(teams[0].name))
    } else {
      dispatch(setGroupIdReducer(''));
      dispatch(setSelectedGroupReducer(''));
    }

  }, [workspace, teams])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (teams.length === 0) {
    return null;
  }
  
  return (
    <>
    {teams.length > 0 && <FormControl sx={{ m: {
      desktop: 1,
      large: 1,
      tablet: 1,
      middle: 1,
      mobile: '1px',
      small: '1px'
    }, minWidth: 120 }}>
        <Button
          disabled={teams.length === 1}
          aria-controls="team-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          sx={{
            width: {
              small: '110px',
              mobile: '110px',
              middle: '140px',
              tablet: '190px',
              large: '210px',
              desktop: '250px',
            },
            borderRadius: '25px',
            backgroundColor: 'white',
            textAlign: 'left',
            color: 'black',
            textTransform: 'none',
          }}
        >
          {team}
        </Button>
        <Menu
          id="team-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: '#f0f0f0',
              borderRadius: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              width: {
                small: '110px',
                mobile: '110px',
                middle: '140px',
                tablet: '190px',
                large: '230px',
                desktop: '250px',
              },
            },
          }}
          MenuListProps={{
            sx: {
              padding: '0',
            },
          }}
        >
          {teams.length === 1 && (
            <MenuItem
              selected={team === teams[0].name}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                  color: 'gray',
                },
                fontSize: {
                  small: '10px',
                  mobile: '10px',
                  middle: '11px',
                  tablet: '12px',
                  large: '13px',
                  desktop: '13px',
                }
              }}
            >
              {team}
            </MenuItem>
          )}

          {teams.length > 1 &&
            teams.map((item, index) => (
              <MenuItem
                key={index}
                selected={team === item.name}
                onClick={() => {
                  handleChange(item);
                  handleClose();
                }}
              >
                {item.name}
              </MenuItem>
            ))}

          {/* {isAdmin && (
            <MenuItem>
              <Button
                onClick={() => {
                  console.log('Button worked');
                }}
                variant="outlined"
                sx={{
                  borderRadius: 5,
                  color: 'gray',
                  border: '1px dashed gray',
                  boxShadow: 'none',
                  width: '100%',
                  textAlign: 'center',
                  '&:hover': {
                    border: '1px dashed gray',
                  },
                  textTransform: 'none',
                }}
              >
                <AddIcon sx={{ pr: '3px' }} />
                Add Team
              </Button>
            </MenuItem>
          )} */}
        </Menu>
      </FormControl>}
    </>
  );
});

export default Dropdown;
