import * as Yup from 'yup';

const BookSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Zà-ÿÀ-Ÿ'-]+( [a-zA-Zà-ÿÀ-Ÿ'-]+)*$/, 'Name must contain characters only')
    .required('Name is required'),
  phone: Yup.string()
    .matches(/^(\+\d{1,3})-(\d{5,})$/, 'Use +***-********** format')
    .required('Phone is required'),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Incorrect email format')
    .required('Email is required'),
});

export default BookSchema;
