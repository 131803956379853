import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    groups: [],
    // только для Settings 
    allGroups: [],
    groupId: '',
    selectedGroup: '',
}

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroupsReducer: (state, action) => {
            state.groups = action.payload;
        },
        addGroupReducer: (state, action) => {
            return {
                ...state,
                groups: [...state.groups, action.payload]
            }
        },
        removeGroupReducer: (state, action) => {
            return {
                ...state,
                groups: state.groups.filter(ws => ws.id !== action.payload)
            }
        },
        setGroupIdReducer: (state, action) => {
            state.groupId = action.payload;
        },
        setSelectedGroupReducer: (state, action) => {
            state.selectedGroup = action.payload;
        },
        setAllGroupsReducer: (state, action) => {
            state.allGroups = action.payload;
        },
        resetGroups: () => initialState
    }
});

export const addGroup = (accessToken, data, spaceId) => async (dispatch) => {
    if (accessToken && data){
        try {
            await axios.post(`/api/public/groups/create`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-space-id': spaceId
                }
            }).then((response) => {
                
                dispatch(addGroupReducer({
                    "id": response.data.id,
                    "name": response.data.name
                }));
                return response; 
            })
        } catch (e){
            console.error("Error adding group ", e);
        }
    }
}

export const removeGroup = (accessToken, groupId, spaceId) => async (dispatch) => {
    if (accessToken && groupId){
        try {
            await axios.delete('/api/public/groups/delete', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-group-id': groupId,
                    'X-space-id': spaceId
                }
            });
            dispatch(removeGroupReducer(groupId));
        } catch (e) {
            console.error('Error removing group or fetching updated groups:', e);
        }
    }
}

export const setGroups = (accessToken, workspaceId) => async (dispatch) => {
    if (workspaceId && accessToken){
        try {
            const response = await axios.get('/api/public/groups/me', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-space-id': workspaceId,
              },
            });
            dispatch(setGroupsReducer(response.data));
        } catch (e) {
            console.error('Error while getting groups:', e);
          }
    }
}

export const fetchAllGroups = (accessToken, workspaceId) => async(dispatch) => {
    if (accessToken && workspaceId){
        try {
            await axios.get('/api/public/groups/all', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-space-id': workspaceId
                }
            }).then(response => {
                dispatch(setAllGroupsReducer(response.data));
            })
        } catch (e){
            console.error("Error getting all groups: ", e);
        }
    }
}

export const fetchMembers = (accessToken, workspaceId, groupId) => async (dispatch) => {
    if (accessToken && groupId && workspaceId)
    try {
        return await axios.get('/api/public/groups/membership/members', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-space-id': workspaceId,
                'X-group-id': groupId
            }
        }).then(response => response.data);
    } catch (e){
        console.error("Error getting members: ", e);
    }
}

export const fetchLeader = (accessToken, workspaceId, groupId) => async (dispatch) => {
    if (accessToken && groupId && workspaceId)
    try {
        return await axios.get('/api/public/groups/membership/leader', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-space-id': workspaceId,
                'X-group-id': groupId
            }
        }).then(response => response.data);
    } catch (e){
        console.error("Error getting leader: ", e);
    }
}

export const { setGroupsReducer, addGroupReducer, removeGroupReducer, setGroupIdReducer, setSelectedGroupReducer, setAllGroupsReducer, resetGroups } = groupSlice.actions;
export default groupSlice.reducer;
