import { Box, Avatar, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';

const avatarContainer = {
    position: 'relative',
    display: 'inline-block',
    width: {small: '35px', mobile: '35px', middle: '35px', tablet: '35px', large: '40px', desktop: '50px'},
    height: {small: '35px', mobile: '35px', middle: '35px', tablet: '35px', large: '40px', desktop: '50px'},
  };
  
  const avatarStyle = {
    width: '100%',
    height: '100%',
    color: 'black',
  };
  
  const plusIconContainer = {
    position: 'absolute',
    bottom: '-5px',
    right: '-5px',
    bottom: 0, 
    right: 0, 
    backgroundColor: 'white',
    borderRadius: '50%',
    width: {
      small: '3px',
      mobile: '3px', 
      middle: '8px',
      tablet: '8px', 
      large: '16px',
      desktop: '20px',
    },
    height: {
      small: '3px',
      mobile: '3px', 
      middle: '8px',
      tablet: '8px', 
      large: '16px',
      desktop: '20px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid lightgray', 
  };

const AvatarWithPlus = () => {

  const user = useSelector(state => state.login.user);
  
  return (
      <Box sx={avatarContainer}>
        <Avatar sx={avatarStyle} src="" alt={user.firstName.charAt(0)+user.lastName.charAt(0)} />
        <IconButton sx={plusIconContainer}>
          <AddIcon sx={{ 
            fontSize: {
              small: '8px',
              mobile: '8px', 
              middle: '14px',
              tablet: '14px', 
              large: '16px',
              desktop: '18px'
            }, 
            color: 'gray' 
          }} />
        </IconButton>
      </Box>
    );
  }

  export default AvatarWithPlus;