import { Box } from '@mui/material';

const sizeProps = {
  width: {
    small: '84.7px',
    mobile: '84.7px',
    middle: '98.8px',
    tablet: '112.9px',
    large: '136.5px',
    desktop: '160px'
  },
  height: {
    small: '84.7px',
    mobile: '84.7px',
    middle: '98.8px',
    tablet: '112.9px',
    large: '136.5px',
    desktop: '160px'
  },
};
export const BoxContainer = ({ sx, children }) => (<Box sx={{ ...sizeProps, ...sx}}>
    {children}
</Box>);
