import { Box, Tabs, Tab } from '@mui/material';
import { useState } from 'react';


const CardTabs = ({ setTabs }) => {
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
    <Tabs
      value={value}
      onChange={handleChange}
      sx={{
        '& .MuiTab-root': {
          backgroundColor: '#c8caca',
          color: 'black',
          borderRadius: '8px 8px 0 0',
          padding: {
            small: '5px 8px',
            mobile: '5px 8px',
            middle: '5px 15px',
            tablet: '5px 20px',
            large: '5px 25px',
            desktop: '5px 30px'
          },
          fontSize: {
            small: '6px',
            mobile: '6px',
            middle: '8px',
            tablet: '10px',
            large: '10px', 
            desktop: '10px'
          },
          fontWeight: 500,
          margin: '0 -1px',
          '&.Mui-selected': {
            backgroundColor: 'white',
            color: 'black',
          },
          '&:hover': {
            backgroundColor: 'white',
          },
          minWidth: 0,
          width: {
            small: '25vw',
            mobile: '20vw',
            middle: '15vw',
            tablet: '12vw',
            large: '12vw', 
            desktop: '12vw'
          },
        },
        '& .MuiTabs-indicator': {
          display: 'none',
        },
        mt: 4,
      }}
    >
      <Tab label="Info" onClick={() => {
        setTabs('info');
      }} />
      <Tab label="Technologies" onClick={() => {
        setTabs('technologies');
      }} />
      <Tab label="SkillsMap" onClick={() => {
        setTabs('skillsmap');
      }}/>
    </Tabs>
  </Box>
    );
  }
  
  export default CardTabs;