import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ownerId: ''
}

const shortMemorySlice = createSlice({
    name: 'shortMemory',
    initialState,
    reducers: { 
        setOwnerId: (state, action) => {
            state.ownerId = action.payload;
        }
    }
});

export const { setOwnerId } = shortMemorySlice.actions;

export default shortMemorySlice.reducer;