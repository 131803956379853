import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    userTechnologies: [],
    technologyTypes: [],
    technologies: [],
    forUser: {},
    forDropdown: {},
    temporaryTechnologies: {}
}

const technologiesSlice = createSlice({
    name: 'technologies',
    initialState,
    reducers: {
        setUserTechnologies: (state, action) => {
            state.userTechnologies = action.payload;
        },
        setTechnologyTypes: (state, action) => {
            state.technologyTypes = action.payload;
        },
        setTechnologies: (state, action) => {
            state.technologies = action.payload;
        },
        setForUser: (state, action) => {
            state.forUser = action.payload;
        },
        setTemporaryTechnologies: (state, action) => {
            state.temporaryTechnologies = action.payload;
        },
        setForDropdown: (state, action) => {
            state.forDropdown = action.payload;
        },
        resetTechnologies: () => initialState,
    }
});


const setUser = (technologyTypes, userTechnologies, allTechnologies) => async (dispatch) => {
    const result = {};
    technologyTypes.forEach(type => {
        result[type.name] = result[type.name] || [];
        allTechnologies.forEach(technology => {
            if (userTechnologies.includes(technology.id) && type.id === technology.technologiesType) {
                const exists = result[type.name].some(item => item.id === technology.id);
                if (!exists) {
                    result[type.name].push({
                        name: technology.name,
                        technologiesType: technology.technologiesType,
                        id: technology.id
                    });
                }
            }
        });
    });
    for (let [type, tech] of Object.entries(result)){
        if (result[type].length === 0){
            delete result[type];
        }
    }
    dispatch(setForUser(result));
    dispatch(setTemporaryTechnologies(result));
};

const setDropdown = (technologyTypes, allTechnologies) => async (dispatch) => {
    let result = {};
    technologyTypes.forEach(type => {
        result[type.name] = result[type.name] || [];
        allTechnologies.forEach(technology => {
            if (type.id === technology.technologiesType) {
                const exists = result[type.name].some(
                    item => item.id === technology.id
                );
                if (!exists) {
                    result[type.name] = [...result[type.name], technology];
                }
            }
        });
    });
    for (let [type, tech] of Object.entries(result)){
        if (result[type].length === 0){
            delete result[type];
        }
    }
    dispatch(setForDropdown(result));
};


export const getTechnologies = (accessToken) => async (dispatch) => {
    try {
        const [typesResponse, technologiesResponse, userTechnologiesResponse] = await Promise.all([
            axios.get('/api/public/users/profile/technologies/types', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }),
            axios.get('/api/public/users/profile/technologies', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }),
            axios.get('/api/public/users/profile/technologies/me', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
        ]);
        const types = typesResponse.data;
        const technologies = technologiesResponse.data;
        const userTechnologies = userTechnologiesResponse.data;

        dispatch(setTechnologies(technologies))
        dispatch(setDropdown(types, technologies));
        dispatch(setUser(types, userTechnologies, technologies));
    } catch (error) {
        console.error("Error fetching technologies data", error);
    }
};

export const editTechnologies = (accessToken, technologies) => async (dispatch) => {
    await axios.put(`/api/public/users/profile/technologies/edit`, technologies, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })
    .then(response => response);
}

export const { setUserTechnologies, setTechnologies, resetTechnologies, setTechnologyTypes, setForDropdown, setForUser, setTemporaryTechnologies } = technologiesSlice.actions;

export default technologiesSlice.reducer;
