const MiniQuibenchLogo = ({ width="35", height="35"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 262 262" fill="none">
        <path d="M125.047 23.8179L232.229 131L125.047 238.182" stroke="#B7A0E7" strokeWidth="35.7273" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M77.4102 59.5454L148.865 131L77.4102 202.455" stroke="#B7A0E7" strokeWidth="35.7273" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="-0.419922" y="131" width="65.5" height="65.5" rx="32.75" transform="rotate(-45 -0.419922 131)" fill="#B7A0E7"/>
        </svg>
    )
}

export default MiniQuibenchLogo;