import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Box, TextField, FormControl } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { Edit } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { removeQuestion, changeQuestion, setQuestions } from '../../../redux/questionSlice';

const CustomAccordion = ({ title, answer, id }) => {
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const spaceId = useSelector(state => state.workspace.selectedSpace);
    const accessToken = useSelector(state => state.auth.accessToken);
    const data = useSelector(state => state.question.items);
    const question = data.find((item) => item.id === id);
    const [saving, setSaving] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();

    const handleChange = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Formik 
            enableReinitialize={true}
            initialValues={{
                title: question ? question.questionText : '',
                answer: question ? question.answerText : ''
            }} 
            onSubmit={(values, { resetForm }) => {
                dispatch(changeQuestion(accessToken, spaceId, values, id))
                .then(() => {
                    dispatch(setQuestions(accessToken, spaceId));
                });
                resetForm();
                setSaving(false);
                setExpanded(false);
            }}
        >
            {({ values }) => (
                <Form>
                    <Accordion
                        expanded={expanded}
                        sx={{
                            backgroundColor: expanded ? "#f5f5f5" : "white",
                            borderRadius: "20px",
                            padding: "10px 0",
                            boxShadow: expanded
                                ? "0 4px 12px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.1)"
                                : "0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.08)",
                        }}
                        disableGutters
                    >
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                          <Box sx={{ display: 'flex', flexDirection: {
                            small: 'column-reverse',
                            mobile: 'column-reverse',
                            middle: 'column-reverse',
                            tablet: 'row',
                            large: 'row',
                            desktop: 'row'
                          }, width: '100%', alignItems: 'center'}}>
                          {saving ? (
                                <FormControl sx={{ display: 'flex', alignItems: 'center'}}>
                                    <Field as={TextField}
                                        name="title"
                                        placeholder="Enter title"
                                        fullWidth
                                    />
                                </FormControl>
                            ) : (
                                <Typography sx={{ fontWeight: 600,  display: 'flex', alignItems: 'center' }}>{values.title}</Typography>
                            )}
                            <Box sx={{ display: "flex", alignItems: "center", pl: 1, flexDirection: 'row' }}>
                                {expanded ? (
                                    !saving && <RemoveIcon sx={{ fontSize: "1.5rem" }} onClick={handleChange} />
                                ) : (
                                    !saving && <AddIcon sx={{ fontSize: "1.5rem" }} onClick={handleChange} />
                                )}
                                {isAdmin && (
                                    <Box>
                                        <IconButton
                                            size="small"
                                            sx={{ marginLeft: "30px" }}
                                            onClick={() => { setSaving(!saving); setExpanded(true); }}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Box>
                                )}
                                {saving && (
                                    <>
                                        <IconButton size="small" sx={{ marginLeft: "30px" }} type="submit">
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton onClick={() => {
                                            dispatch(removeQuestion(accessToken, spaceId,  id));
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )}
                            </Box>
                          </Box>
                            
                        </AccordionSummary>

                        
                        <AccordionDetails
                            sx={{
                                maxHeight: expanded ? "1000px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.5s ease-out",
                            }}
                        >
                            {saving ? (
                                <>
                                    <FormControl sx={{ mb: 2 }}>
                                        <Field as={TextField}
                                            name="type"
                                            placeholder="Enter type"
                                            fullWidth
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Field as={TextField}
                                            name="answer"
                                            placeholder="Enter answer"
                                            fullWidth
                                        />
                                    </FormControl>
                                </>
                            ) : (
                                <Typography
                                    sx={{
                                        color: "gray",
                                        fontSize: {
                                            desktop: "14px",
                                            large: "12px",
                                            tablet: "12px",
                                            middle: "12px",
                                            mobile: "10px",
                                            small: "10px",
                                        },
                                    }}
                                >
                                    {values.answer}
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Form>
            )}
        </Formik>
    );    
};

export default CustomAccordion;
