import Dashboard from "./Dashboard";
import Questions from "./Questions";
import Members from "./Members";
import Projects from "./Projects";
import UserHeader from "../Header/UserHeader";
import Tabs from "./Tabs";
import { Container, Box } from "@mui/material";
import FullProfile from "../Profile/FullProfile";
import { useParams } from 'react-router-dom';
import { Knowledge } from './Knowledge';
import { Interviews } from './Interviews';

const Switcher = () => {
  const { section } = useParams();

  const renderComponent = () => {
    switch (section) {
      case 'dashboard':
        return <Dashboard />;
      case 'questions':
        return <Box sx={{ width: '70%' }}><Questions /></Box>;
      case 'members':
        return <Members />;
      case 'projects':
        return <Projects />;
      case 'profile':
        return <Box sx={{ width: '82%' }}><FullProfile /></Box>;
      case 'knowledge': 
        return <Box sx={{ width: '75% '}}><Knowledge /></Box>
      case 'interviews':
        return <Box sx={{ width: '70%' }}><Interviews /></Box>
      default:
        return <div>Page Not Found</div>;
    }
  };

  return (
    <Container>
      <UserHeader />
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mt: 5, mr: 1 }}>
        <Tabs />
        {renderComponent()}
      </Box>
    </Container>
  );
};

export default Switcher;