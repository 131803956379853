import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    authToken: '',
    accessToken: '',
    refreshToken: '',
    expiresIn: 0
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      setAuthToken: (state, action) => {
        state.authToken = action.payload;
      },
      clearAuthToken: (state) => {
        state.authToken = '';
      },
  
      setAccessToken: (state, action) => {
        state.accessToken = action.payload;
        console.log(state.accessToken);
      },
      clearAccessToken: (state) => {
        state.accessToken = '';
      },
  
      setRefreshToken: (state, action) => {
        state.refreshToken = action.payload;
      },
      clearRefreshToken: (state) => {
        state.refreshToken = '';
      },

      setExpiresIn: (state, action) => {
        state.expiresIn = action.payload;
      },
      clearExpiresIn: (state) => {
        state.expiresIn = 0;
      },
      resetAuth: () => initialState,
    },
  });

  export const {
    setAuthToken,
    clearAuthToken,
    setAccessToken,
    clearAccessToken,
    setRefreshToken,
    clearRefreshToken,
    setExpiresIn,
    clearExpiresIn,
    resetAuth
  } = authSlice.actions;

  export const startInterval = (refreshToken, period) => (dispatch) => {
    const refreshTokens = (currentRefreshToken, currentPeriod) => {
      setTimeout(() => {
        axios.post('/api/public/auth/refresh', {}, {
          headers: {
            Authorization: `Bearer ${currentRefreshToken}`
          }
        }).then(response => {
          const newAccessToken = response.data.access_token;
          const newRefreshToken = response.data.refresh_token;
          const newExpiresIn = response.data.expires_in;
          dispatch(setAccessToken(newAccessToken));
          dispatch(setRefreshToken(newRefreshToken));
          dispatch(setExpiresIn(newExpiresIn));
          refreshTokens(newRefreshToken, newExpiresIn);
          console.log(" token was refreshed ")
        }).catch((error) => {
          console.error("Error happened while refreshing tokens: ", error);
        });
      }, currentPeriod * 1000);
    }
    refreshTokens(refreshToken, period);
  };
  
  export default authSlice.reducer;