const Skype = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 509 511.91"
            width="24" 
            height="24" 
        >
            <path
                fillRule="nonzero"
                d="M490.29 297.77c2.37-13.58 3.61-27.55 3.61-41.82 0-132.96-107.19-240.77-239.41-240.77-14.19 0-28.08 1.23-41.58 3.62C192.12 6.82 168.02 0 142.34 0 63.72 0 0 64.1 0 143.16c0 25.82 6.78 50.07 18.71 70.98-2.39 13.57-3.61 27.54-3.61 41.81 0 132.97 107.19 240.79 239.39 240.79 14.2 0 28.09-1.25 41.6-3.64 20.79 11.99 44.89 18.81 70.55 18.81 78.62 0 142.36-64.09 142.36-143.17 0-25.82-6.81-50.06-18.71-70.97zM255.48 409.96c-85.06 0-123.92-43.3-123.92-75.11 0-16.32 12.78-27.54 29.12-27.54 36.38 0 26.91 54.59 94.8 54.59 34.74 0 55.09-20.91 55.09-40.52 0-11.8-6.76-24.92-29.64-30.66l-75.63-19.17c-60.9-15.48-71.58-49.38-71.58-80.78 0-65.24 59.63-88.82 116.47-88.82 52.32 0 114.53 28.87 114.53 68.02 0 16.77-14.07 25.89-30.42 25.89-31.06 0-25.88-43.68-88.44-43.68-31.06 0-47.43 14.54-47.43 34.97 0 20.38 24.02 27.3 45.31 32.22l55.97 12.62c61.32 13.85 77.72 49.8 77.72 84.04 0 53.01-41.05 93.93-121.95 93.93z"
            />
        </svg>
    );
};

export default Skype;
