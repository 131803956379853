import { Container, Box, Typography } from "@mui/material";
import Dropdown from "./Dropdown";
import ProfileDropdown from "./ProfileDropdown";
import { useMediaQuery } from "@mui/material";
import {memo, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData, loggedIn } from "../../redux/loginSlice";
import { setSelectedSpace, setWorkSpace } from "../../redux/workspaceSlice";
import Settings from "../Settings/Settings";
import { setGroups } from "../../redux/groupSlice";

const UserHeader = memo(() => {

  const smallScreen = useMediaQuery(theme => theme.breakpoints.down('middle'));
  const accessToken = useSelector(state => state.auth.accessToken);
  const open = useSelector(state => state.settings.isOpen);
  const item = useSelector(state => state.workspace.settingsItem);
  const dispatch = useDispatch();
  const spaces = useSelector(state => state.workspace.workspaces);
  const selectedSpace = useSelector(state => state.workspace.selectedSpace);

  useEffect(() => {
    dispatch(fetchUserData(accessToken)); 
    dispatch(setWorkSpace(accessToken));
  }, []);

  useEffect(() => {
      if (spaces.length > 0 && !selectedSpace || selectedSpace === ''){
        dispatch(setSelectedSpace(spaces[0].id))
      }
  }, [spaces])
  
    return (
        <>
          <Container sx={{backgroundColor: '#f5f5f5'}}>
          <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: '#f5f5f5',
              height: '100px',
              alignItems: 'center',
              width: '95%',
            }}>

            <Box>
                <Dropdown></Dropdown>
            </Box>
           
           {!smallScreen && <Box>
                <Typography sx={{fontSize: {middle: '15px', tablet: '20px', large: '25px', desktop: '25px'}, mr: 5}}> Header </Typography>
            </Box>}

            <Box>

                <ProfileDropdown></ProfileDropdown>
            </Box>
            
            </Box>
        
            </Container>
            <Settings open={open} item={item}></Settings>
        </>
    )

})


export default UserHeader;