import { Button, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setElement } from '../../redux/profileSlice';
import { setQuestions } from '../../redux/questionSlice';
import { useNavigate } from 'react-router-dom';
import { SatelliteTwoTone } from '@mui/icons-material';

export const button = {
    backgroundColor: 'transparent',
    borderRadius: '25px',
    border: 'none',
    color: 'black',
    mb: 3,
    fontSize: {
      small: '8px',
      mobile: '8px',
      middle: '9px', 
      tablet: '9px',
      large: '10px',
      desktop: '11px',
    },
    textAlign: 'left',
    padding: {
      small: '5px 10px',
      mobile: '5px 10x',
      middle: '5px 10px',
      tablet: '10px 20px',
      large: '10px 20px',
      desktop: '10px 20px'
    },
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    boxShadow: '0 0 2px lightgray',
    maxWidth: '200px'
  };
  
  const Tabs = () => {

    const dispatch = useDispatch();
    // const groups = useSelector(state => state.group.groups);
    const navigate = useNavigate();
    const spaces = useSelector(state => state.workspace.workspaces);
    const accessToken = useSelector(state => state.auth.accessToken);
    const spaceid = useSelector(state => state.workspace.selectedSpace);

    return (
      <>
        {spaces.length > 0 && <Box sx={{ 
          border: 'divider', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
          height: '100%',
          pr: { small: 2, mobile: 2, middle: 4, tablet: 4, large: 5, desktop: 6 },
          ml: { small: 0, mobile: 0, middle: 2, tablet: 3, large: 4, desktop: 4 },
          mr: { small: 2, mobile: 2, middle: 4, tablet: 4, large: 5, desktop: 6 }, 
          borderRight: {
          small: '1px solid gray',
          mobile: '1px solid gray',
          tablet: '1px solid gray',
          large: '1px solid gray',
          desktop: '1px solid gray',
        }}}>
          
          <Button sx={button} onClick={() => { dispatch(setElement('dashboard')); navigate('/dashboard') }}>Dashboard</Button>
          
          <Button sx={button} onClick={() => { dispatch(setElement("projects")); navigate('/projects') }}>Projects</Button>
          
          <Button sx={button} onClick={() => { dispatch(setElement("members")); navigate('/members') }}>Members</Button>
          
          <Button sx={button} onClick={() => { dispatch(setElement("faq")); navigate('/questions') }}>FAQ</Button>

          <Button sx={button} onClick={() => { dispatch(setElement('interviews')); navigate('/interviews')}}>Interviews</Button>
      
          <Button sx={button} onClick={() => { dispatch(setElement('knowledge')); navigate('/knowledge')}}>Knowledge</Button>

        </Box>}
      </>
    );
  }
export default Tabs;
