import { useLocation } from "react-router-dom";
import UserHeader from "./UserHeader";
import DefaultHeader from "./DefaultHeader";
import { memo } from 'react';
import { Box } from "@mui/material";

const Header = memo(() => {

    const location = useLocation();
    const path = location.pathname;

    return (
        <>
            { path === '/' && <DefaultHeader /> }
            { path === '/userProfile' && <UserHeader /> }
        </>
    );
})

export default Header;
