import { Routes, Route }from 'react-router-dom';
import Home from './pages/Home';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Layout from './Layout';
import CreateWorkspace from './components/Workspace/CreateWorkspace';
import Switcher from './components/SwitchComponents/Switcher';

const AppRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/createWorkspace" element={<CreateWorkspace />} />
            <Route path="/:section" element={<Switcher />} />
          </Route>
      </Routes>      
    )
}

export default AppRoutes;
