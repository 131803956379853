import * as Yup from 'yup';

const validationSchema = (isRegister) =>
    Yup.object().shape({
      firstName: isRegister
        ? Yup.string()
            .matches(/^[A-Za-zА-Яа-яЁёĄĆĘŁŃÓŚŻŹąćęłńóśżź'-]+$/, 'First Name cannot contain special symbols or numbers')
            .required('First Name is required')
        : Yup.string(),
      lastName: isRegister
        ? Yup.string()
            .matches(/^[A-Za-zА-Яа-яЁёĄĆĘŁŃÓŚŻŹąćęłńóśżź'-]+$/, 'Last Name cannot contain special symbols or numbers')
            .required('Last Name is required')
        : Yup.string(),
      email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Incorrect email format')
      .required('Email is required'),
      password: isRegister ? Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must contain at least 1 capital letter, 1 small letter, 1 special symbol, 1 number and minimum length is 8 symbols').required('Password is required') 
      : Yup.string().required('Enter password'),
      repeatPassword: isRegister
        ? Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Repeat Password is required')
        : Yup.string(),
      checkbox: isRegister
        ? Yup.boolean().oneOf([true], 'You must agree to the terms')
        : Yup.boolean(),
    });

    export default validationSchema;