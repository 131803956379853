import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    user: {},
    isAdmin: false,
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loggedIn: (state, action) => {
            state.user = action.payload.user;
        },
        loggedOut: (state) => {
            state.user = {};
        },
        setAdmin: (state) => {
            state.isAdmin = true;
        },
        resetLogin:() => initialState
    }
});

export const fetchUserData = (accessToken) => async (dispatch) => {

    await axios.get('/api/public/users/profiles/me', {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }).then(response => {
        dispatch(loggedIn({ user: response.data}));        
    });
}

export const updateUserData = (accessToken, data) => async (dispatch) => {

    const response = await axios.put('/api/public/users/profiles/me/update', data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": 'application/json'
        }
    });
    console.log("data updated" )
    dispatch(loggedIn({user: response.data}));
}

export const { loggedIn, loggedOut, setAdmin, resetLogin } = loginSlice.actions;
export default loginSlice.reducer;