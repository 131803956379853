import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setOwnerId } from "./shortMemorySlice";

const initialState = {
    workspaces: [],
    workspaceId: '',
    selectedSpace: null,
    selectedName: '',
    selectedSite: ''
}

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        setWorkspace: (state, action) => {
            state.workspaces = action.payload;
        },
        addWorkspace: (state, action) => {
            return {
                ...state,
                workspaces: [...state.workspaces, action.payload]
            }
        },
        removeWorkspace: (state, action) => {
            return {
                ...state,
                workspaces: state.workspaces.filter(ws => ws.id !== action.payload)
            }
        },
        setWorkSpaceId: (state, action) => {
            state.workspaceId = action.payload;
        },
        setSelectedSpace: (state, action) => {
            state.selectedSpace = action.payload;
        },
        setSelectedName: (state, action) => {
            state.selectedName = action.payload;
            console.log(state.selectedName)
        },

        setSelectedSite: (state, action) => {
            state.selectedSite = action.payload;
        },
        resetWorkspaces: () => initialState,
    }
});

export const addWorkSpace = (accessToken, data) => async (dispatch) => {
        if (accessToken && data){
            try {
                const add = await axios.post('/api/public/spaces/create', data, {
                    headers: { Authorization: `Bearer ${accessToken}` }
                });
                dispatch(addWorkspace(add.data));
            } catch (e){
                console.error("Error adding workspace", e);
            }
        } else {
        console.log("Access token or id is not valid")
    }
}

export const closeWorkSpace = (accessToken, spaceId) => async (dispatch) => {
    if (accessToken && spaceId){
        try {
            await axios.post(
                '/api/public/spaces/settings/close'
                , {}, {
                headers: {
                  'X-space-id': spaceId,
                  Authorization: `Bearer ${accessToken}`
                },
              }).then(response => {
                return response;
              });
              dispatch(removeWorkspace(spaceId));
        } catch (e){
            console.error("Error adding workspace", e);
        }
    } else {
        console.log("Access token or id is not valid")
    }
}

export const setWorkSpace = (accessToken) => async (dispatch) => {
    if (accessToken){
        try {
            const spaces = await axios.get('/api/public/spaces/me', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            dispatch(setWorkspace(spaces.data));
        } catch (e){
            console.error("Error adding workspace", e);
        }
    } else {
        console.log("Access token is not provided")
    }
}

export const getCurrentSpace = (accessToken, spaceId, ownerId) => async (dispatch) => {
   if (accessToken){
        try {
            const response = await axios.get(
                'api/public/spaces/settings/info'
                , {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-space-id': spaceId
                }
            });
            dispatch(setOwnerId(response.data.ownerId));
            return response;
        } catch (e){
            console.error("Error getting current space: ", e);
        }
   }
}

export const { addWorkspace, removeWorkspace, setWorkspace, setWorkSpaceId, resetWorkspaces, setSelectedSpace, setSelectedName, setSelectedSite } = workspaceSlice.actions;
export default workspaceSlice.reducer;