import { 
    Box,
    FormControl, 
    Typography, 
    ListSubheader, 
    InputLabel,
    OutlinedInput, 
    Chip,
    Select,
    MenuItem,
    Button,
    useMediaQuery
} from '@mui/material';
import { useState, useEffect, memo } from 'react';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme }from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTechnologies, editTechnologies, setTemporaryTechnologies } from '../../redux/technologiesSlice';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
    },
  },
};

const typography = {
    fontSize: {
        small: '15px',
        mobile: '15px',
        tablet: '17px',
        middle :'19px',
        large: '21px',
        desktop: '22px'
    },
    fontWeight: 600
}

const Skills = memo(({ add, setAdd }) => {

    const accessToken = useSelector(state => state.auth.accessToken);
    const dropdownTechnologies = useSelector(state => state.technologies.forDropdown);
    const temporaryTechnologies = useSelector(state => state.technologies.temporaryTechnologies);
    const theme = useTheme()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectOpen, setSelectOpen] = useState(false);
    const isMobileScreen = useMediaQuery('(max-width: 400px)');
    const isBetweenMobileAndMiddle = useMediaQuery('(max-width: 600px) and (min-width: 401px)');
    const isLargeScreen = useMediaQuery('(min-width: 601px)');

    const renderCategory = (type, technologies) => {
        return (
            <>
                <Typography sx={typography}>{type.charAt(0).toUpperCase() + type.slice(1)}</Typography>
                <Box>{renderChips(technologies)}</Box>
            </>
        );
    };

    const renderChips = (items) => {
        const remainder = items.length % 3;
        const base = Math.floor(items.length / 3);
        const firstPart = base + (remainder > 0 ? 1 : 0);
        const secondPart = base * 2 + (remainder > 1 ? 1 : 0);

        return items.map((item, index) => {
            let bgColor;
            let color;
            if (index < firstPart) {
                bgColor = '#5c5b5b';
                color = 'white';
            } else if (index < secondPart) {
                bgColor = '#6d6e6d';
                color = 'black';
            } else {
                bgColor = '#9a9c9a';
                color = 'black';
            }

            return (
                <Chip
                    key={index}
                    label={item.name}
                    sx={{
                        backgroundColor: bgColor,
                        color: color,
                        mr: 1,
                        mb: '6px',
                        mt: '6px',
                    }}
                    deleteIcon={add ? <ClearIcon sx={{ color: 'gray' }} /> : <></>}
                    onDelete={() => {
                        if (add) {
                            handleDeleteTechnology(item.id);
                        }
                    }}
                />
            );
        });
    };

    const handleDeleteTechnology = (technologyId) => {
        let newTechnologies = { ...temporaryTechnologies };
        for (let [type, technologies] of Object.entries(temporaryTechnologies)) {
            const newTechs = technologies.filter(technologyObject => technologyObject.id !== technologyId);
            newTechnologies[type] = newTechs;
        }
        dispatch(setTemporaryTechnologies(newTechnologies));
    };

    const handleSelectTechnology = (key, technology) => {
        let newTemporary = { ...temporaryTechnologies };
        if (!newTemporary[key]) {
            newTemporary[key] = [technology];
        }
        else if (!newTemporary[key].some(item => item.id === technology.id)) {
            newTemporary[key] = [...newTemporary[key], technology];
        }    
        dispatch(setTemporaryTechnologies(newTemporary));
    };

    useEffect(() => {
        dispatch(getTechnologies(accessToken));
    }, [dispatch, accessToken]);

    return (
        <>
            <Box sx={{ mt: 2, mb: 5, width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 2 }}>
                    <ArrowBackIcon sx={{ mr: 2 }} />
                    Skills
                </Box>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    In this section, you can add the skills you have.
                </Typography>

                {add ? (
                    <FormControl fullWidth>
                    <InputLabel id="select-technologies-label">Select Technologies</InputLabel>
                    <Select
                        labelId="select-technologies-label"
                        id="demo-multiple-chip"
                        multiple
                        value={Object.values(temporaryTechnologies).flat()}
                        onOpen={() => setSelectOpen(true)}
                        onClose={() => setSelectOpen(false)}
                        open={selectOpen}
                        label="Select Technologies"
                        aria-label="Select technologies"
                        input={<OutlinedInput id="select-multiple-chip" label="Select Technologies" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, flexDirection: 'row' }}>
                                {selected.slice(0, isMobileScreen ? 1 : isBetweenMobileAndMiddle ? 2 : 3).map((technology) => (
                                    <Chip
                                        key={technology.id}
                                        label={technology.name}
                                        onClick={() => handleDeleteTechnology(technology.id)}
                                    />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(dropdownTechnologies).map(([type, technologies]) => (
                            <Box key={type}>
                                <ListSubheader sx={{ color: 'gray' }}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </ListSubheader>
                                {technologies.map((technology, index) => (
                                    <MenuItem
                                        key={`${technology.id}-${index}`}
                                        value={technology.id}
                                        onClick={() => {
                                            handleSelectTechnology(type, technology);
                                        }}
                                    >
                                        {technology.name}
                                    </MenuItem>
                                ))}
                            </Box>
                        ))}
                    </Select>
                </FormControl>
                
                ) : (
                    <Box
                        onClick={() => setAdd(true)}
                        sx={{
                            border: '2px dashed gray',
                            padding: '8px 12px',
                            color: 'gray',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            mt: 3,
                            fontSize: '13px',
                            borderRadius: '20px',
                            alignItems: 'center',
                            '&:hover': {
                                backgroundColor: 'lightgray',
                            },
                        }}>
                        <AddIcon /> Add Skills
                    </Box>
                )}

                <Box sx={{ mt: 3 }}>
                    {Object.entries(temporaryTechnologies).map(([type, technologies]) => {
                        return <Box key={type}>{renderCategory(type, technologies)}</Box>;
                    })}
                </Box>

                {add && (
                    <Button
                        onClick={() => {
                            const allTechnologyIds = Object.values(temporaryTechnologies)
                            .flat()
                            .map(item => item.id); 
                            dispatch(editTechnologies(accessToken, allTechnologyIds));
                            setAdd(false);
                            navigate('/profile');
                        }}
                        sx={{
                            width: '180px',
                            borderRadius: '20px',
                            pt: 1,
                            pb: 1,
                            mt: 1,
                            backgroundColor: 'black',
                            color: 'white',
                        }}>
                        Save
                    </Button>
                )}
            </Box>
        </>
    );
});

export default Skills;
