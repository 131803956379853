import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginSlice from "./redux/loginSlice";
import authSlice from "./redux/authSlice";
import workspaceSlice from "./redux/workspaceSlice";
import profileSlice from "./redux/profileSlice";
import groupSlice from "./redux/groupSlice";
import questionSlice from "./redux/questionSlice";
import settingsSlice from "./redux/settingsSlice";
import participantsSlice from "./redux/participantsSlice";
import technologiesSlice from './redux/technologiesSlice';
import { resetAuth } from "./redux/authSlice";
import { resetGroups } from "./redux/groupSlice";
import { resetParticipants } from "./redux/participantsSlice";
import { resetLogin } from "./redux/loginSlice";
import { resetElement } from "./redux/profileSlice";
import { resetSettings } from "./redux/settingsSlice";
import { resetTechnologies } from "./redux/technologiesSlice";
import { resetQuestions } from "./redux/questionSlice";
import { resetWorkspaces } from "./redux/workspaceSlice";
import  shortMemorySlice  from './redux/shortMemorySlice';

const rootReducer = combineReducers({
  login: loginSlice,
  auth: authSlice,
  workspace: workspaceSlice,
  profile: profileSlice,
  group: groupSlice,
  question: questionSlice,
  settings: settingsSlice,
  participants: participantsSlice,
  technologies: technologiesSlice,
  shortMemory: shortMemorySlice
});

const persistConfig = {
  key: "root",
  whitelist: ['workspace', 'auth', 'login', 'widget', 'group'],
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export const clearStorage = () => {
  store.dispatch(resetAuth());
  store.dispatch(resetSettings());
  store.dispatch(resetGroups());
  store.dispatch(resetParticipants());
  store.dispatch(resetTechnologies());
  store.dispatch(resetWorkspaces());
  store.dispatch(resetQuestions());
  store.dispatch(resetElement());
  store.dispatch(resetLogin());

}

export { store, persistor };

