import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    settingsItem: {},
    panelElement: 'General',
    elementContent: '',
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setOpen: (state) => {
            state.isOpen = true;
        },
        setClose: (state) => {
            state.isOpen = false;
            state.panelElement = 'Settings';
        },
        setSettingsItem: (state, action) => {
            state.settingsItem = action.payload;
        },
        setElement: (state, action) => {
            state.panelElement = action.payload;
        },
        setContent: (state, action) => {
            state.elementContent = action.payload;
        },
        resetSettings: () => initialState
    }
});

export const { setOpen, setClose, setSettingsItem, resetSettings, setElement, setContent } = settingsSlice.actions;

export default settingsSlice.reducer;
