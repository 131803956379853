
export const Linkedin = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.375 2.0625C2.85723 2.0625 2.4375 2.48223 2.4375 3C2.4375 3.51777 2.85723 3.9375 3.375 3.9375C3.89277 3.9375 4.3125 3.51777 4.3125 3C4.3125 2.48223 3.89277 2.0625 3.375 2.0625ZM1.3125 3C1.3125 1.86091 2.23591 0.9375 3.375 0.9375C4.51409 0.9375 5.4375 1.86091 5.4375 3C5.4375 4.13909 4.51409 5.0625 3.375 5.0625C2.23591 5.0625 1.3125 4.13909 1.3125 3Z" fill="#1C1B1E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.3125 6.75C1.3125 6.43934 1.56434 6.1875 1.875 6.1875H4.875C5.18566 6.1875 5.4375 6.43934 5.4375 6.75V16.125C5.4375 16.4357 5.18566 16.6875 4.875 16.6875H1.875C1.56434 16.6875 1.3125 16.4357 1.3125 16.125V6.75ZM2.4375 7.3125V15.5625H4.3125V7.3125H2.4375Z" fill="#1C1B1E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.46601 7.73165C9.23768 6.88015 10.4144 6.1875 12 6.1875C13.5856 6.1875 14.7623 6.88015 15.534 7.73165C16.285 8.56038 16.6875 9.58025 16.6875 10.3125V16.125C16.6875 16.4357 16.4357 16.6875 16.125 16.6875H13.5C13.1893 16.6875 12.9375 16.4357 12.9375 16.125V10.875C12.9375 10.6279 12.8404 10.328 12.6593 10.0975C12.4853 9.87607 12.2623 9.75 12 9.75C11.6943 9.75 11.4772 9.86646 11.3278 10.043C11.1705 10.229 11.0625 10.5149 11.0625 10.875V16.125C11.0625 16.4357 10.8107 16.6875 10.5 16.6875H7.875C7.56434 16.6875 7.3125 16.4357 7.3125 16.125V10.3125C7.3125 9.58025 7.71497 8.56038 8.46601 7.73165ZM9.29962 8.4871C8.69128 9.15837 8.4375 9.91975 8.4375 10.3125V15.5625H9.9375V10.875C9.9375 10.2976 10.1107 9.73979 10.469 9.31634C10.8353 8.88354 11.3682 8.625 12 8.625C12.6752 8.625 13.2022 8.96768 13.5439 9.40248C13.8784 9.82824 14.0625 10.3721 14.0625 10.875V15.5625H15.5625V10.3125C15.5625 9.91975 15.3087 9.15837 14.7004 8.4871C14.1127 7.8386 13.2269 7.3125 12 7.3125C10.7731 7.3125 9.88732 7.8386 9.29962 8.4871Z" fill="#1C1B1E"/>
        </svg>
    )
}