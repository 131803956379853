import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import SupportIcon from '@mui/icons-material/HelpOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';
import ProfileButton from '../Profile/ProfileButton';
import { memo } from 'react';
import { useSelector } from 'react-redux';

const ProfileDropdown = memo(() => {
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector(state => state.login.user);
  const firstLast = user.firstName && user.lastName;
  const name = useSelector(state => state.workspace.selectedName);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ 
      backgroundColor: 'transparent', 
      boxShadow: 'none', 
    }}>
      <Toolbar sx={{ 
        justifyContent: 'space-between', 
        p: 0, 
        m: 0,
      }}>
        <IconButton 
          aria-label="support" 
          sx={{ 
            '&:hover': {
                backgroundColor: '#f5f5f5'
              },
            display: 'flex', 
            alignItems: 'center',
            p: 0, 
            m: 0,
          }}
        >
          <SupportIcon sx={{ 
            color: 'black',
            pr: '10px',
            width: { 
              small: '10px',
              mobile: '15px',
              middle: '20px',
              tablet: '25px',
              large: '30px',
              desktop: '30px'
            }, 
            height: { 
              small: '10px',
              mobile: '15px',
              middle: '20px',
              tablet: '25px',
              large: '30px',
              desktop: '30px'
            },
          }} />
          <Typography
            sx={{
              marginLeft: 1,
              color: 'black',
              display: {
                small: 'none',
                mobile: 'none',
                middle: 'none',
                tablet: 'inline',
                large: 'inline',   
                desktop: 'inline' 
              },
              pr: '30px',
            }}
          >
            Support
          </Typography>
        </IconButton>
    
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          border: 'none',
          overflow: 'visible'
        }}>
          <Avatar sx={{ 
            p: '2px',
            border: '2px solid white',
            mr: '15px',
            color: 'black',
            backgroundColor: '#f5f5f5',
            width: {
              small: '25px',
                mobile: '25px',
                middle: '30px',
                tablet: '30px',
                large: '35px',   
                desktop: '40px' 
            },
            height: {
              small: '25px',
              mobile: '25px',
              middle: '30px',
              tablet: '30px',
              large: '35px',   
              desktop: '40px'  
            },
            fontSize: {
              small: '10px',
              mobile: '10px',
              middle: '11px',
              tablet: '13px',
              large: '14px',   
              desktop: '16px' 
            }
          }}>{firstLast ? user.firstName.charAt(0) + user.lastName.charAt(0) : 'SK'}</Avatar>
    
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
          }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'black',
                  fontSize: {
                    small: '10px',
                    mobile: '10px',
                    middle: '12px',
                    tablet: '14px',
                    large: '16px',
                    desktop: '18px'
                  },
                }}
              >
                {user.firstName || '' + ' ' + user.middleName || '' + ' ' + user.lastName || '' }
              </Typography>
              <IconButton onClick={handleMenuClick} color="inherit" sx={{ p: 0, m: 0, border: 'none', overflow: 'visible' }}>
                <ArrowDropDownIcon sx={{ color: 'black', position: 'relative', p: 0, m: 0, border: 'none', overflow: 'visible' }} />
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: 'gray',
                mt: -1,
                fontSize: {
                  small: '8px',
                  mobile: '8px',
                  middle: '10px',
                  tablet: '12px',
                  large: '14px',
                  desktop: '16px'
                },
                p: 0, 
                m: 0,
              }}
            >
              {name}
            </Typography>
          </Box>
    
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{
              zIndex: 1300,
              mt: 3,
              position: 'absolute',
              border: 'none',
              overflow: 'visible',
              '& .MuiPaper-root': {
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                p: 0, 
                m: 0,
              },
            }}
          >
            <MenuItem 
              onClick={handleMenuClose} 
              sx={{
                backgroundColor: 'white', 
                '&:hover': { backgroundColor: 'white' },
                p: 0, 
                m: 0,
              }}
            >
              <ProfileButton />
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
});

export default ProfileDropdown;
