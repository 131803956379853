import './App.css';
import AppRoutes from './AppRouter';
import {memo} from 'react';

const App = memo(() => {
  return (
    <AppRoutes />
  )
})

export default App;
