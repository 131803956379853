import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Box, Typography, IconButton } from "@mui/material";
import { setParticipants } from "../../redux/participantsSlice";
import { removeParticipant } from '../../redux/participantsSlice';
import ClearIcon from '@mui/icons-material/Clear';

const Participants = memo(() => {

    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.auth.accessToken);
    const spaceId = useSelector(state => state.workspace.workspaceId);
    const participants = useSelector(state => state.participants.participants);
    const user = useSelector(state => state.login.user);
    const [data, setData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const ownerId = useSelector(state => state.shortMemory.ownerId);

    useEffect(() => {
        if (spaceId){
            dispatch(setParticipants(accessToken, spaceId));
        }
    }, []);

    useEffect(() => {
        if (participants.length > 0) {
            setDataForTable()
        }
    }, [participants]);

    const handleChange = (index, field, value) => {
        const updatedValues = [...selectedValues];
        updatedValues[index][field] = value;
        setSelectedValues(updatedValues);
    };

    const setDataForTable = () => {
        const initialData = participants.map(participant => ({
            user: {
                firstName: participant.spaceParticipantDto.firstName,
                lastName: participant.spaceParticipantDto.lastName,
                contactEmail: 'test@gmail.com',
                userId: participant.spaceParticipantDto.userId
            },
            groups: participant.groups || [],
            role: ['role 1', 'role 2', 'role 3'] 
        }));

        const initialSelectedValues = initialData.map(item => ({
            groups: item.groups || [],
            role: item.role[0] || ''
        }));

        setData(initialData);
        setSelectedValues(initialSelectedValues);
    }

    return (
        <>
            {participants && 
                <TableContainer 
                    component={Paper} 
                    sx={{
                        maxHeight: '390px', 
                        overflowY: 'auto', 
                        width: '100%',
                        boxShadow: 3,
                        borderRadius: 2,
                    }}>
                    <Table stickyHeader aria-label="participants table" sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h5">Name</Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="h5">Groups</Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="h5">Role</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="h6">
                                            {row.user.firstName} {row.user.lastName}
                                        </Typography>
                                        <Typography variant="h5">
                                            {row.user.contactEmail}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {selectedValues[index].groups.slice(0, 3).map((item, ind, arr) => (
                                            <Typography
                                                key={ind}
                                                sx={{
                                                    fontSize: {
                                                        small: '10px',
                                                        mobile: '10px',
                                                        middle: '11px',
                                                        tablet: '12px',
                                                        large: '13px',
                                                        desktop: '14px'
                                                    },
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {item.name}{ind < arr.length - 1 ? ',\t' : ' '}
                                            </Typography>
                                        ))}

                                        </Box>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Select
                                            value={selectedValues[index].role}
                                            onChange={(e) => handleChange(index, 'role', e.target.value)}
                                            sx={{
                                                minWidth: '100px',
                                                fontSize: {
                                                    small: '8px',
                                                    mobile: '10px',
                                                    middle: '12px',
                                                    tablet: '14px',
                                                    large: '16px',
                                                    desktop: '18px'
                                                },
                                            }}>
                                            {row.role.map((role, i) => (
                                                <MenuItem
                                                    key={i}
                                                    value={role}
                                                    sx={{
                                                        fontSize: {
                                                            small: '8px',
                                                            mobile: '10px',
                                                            middle: '12px',
                                                            tablet: '14px',
                                                            large: '16px',
                                                            desktop: '18px'
                                                        },
                                                    }}
                                                >
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <IconButton disabled={row.user.userId === user.userId || row.user.userId === ownerId} onClick={() => {
                                                dispatch(removeParticipant(accessToken, spaceId, row.user.userId))
                                                dispatch(setParticipants(accessToken, spaceId));
                                                setDataForTable();
                                        }}>
                                            <ClearIcon />
                                        </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
    
});

export default Participants;
