import { Box, Modal, Typography, Button  } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setClose, setElement } from '../../redux/settingsSlice';
import Participants from './Participants';
import AddParticipant from './AddParticipants';
import General from './General';
import Groups from './Groups';

const Settings = ({ open, item }) => {

  const dispatch = useDispatch()
  const panelElement = useSelector(stater => stater.settings.panelElement);

  const handleClose = () => {
    dispatch(setClose());
    dispatch(setElement('General'))
  }

  const handleContentChange = (content) => {
    dispatch(setElement(content))
  };

  return (
    <Modal open={open} onClose={handleClose} sx={{ zIndex: 1300 }}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: {
        small: '90vw',
        mobile: '90vw',
        middle: '80vw',
        tablet: '70vw',
        large: '70vw',
        desktop: '70vw',
      },
      height: '70vh',
      bgcolor: 'background.paper',
      boxShadow: 24,
      pr: 2,
      pt: 2,
      pb: 2,
      display: 'flex',
    }}
  >
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid lightgray',
        width: '20%',
      }}
    >
      <Box
        variant="contained"
        onClick={() => handleContentChange('General')}
        sx={{
          fontSize: {
            small: '8px',
            mobile: '8px',
            middle: '9px',
            tablet: '9px',
            large: '10px',
            desktop: '11px',
          },
          padding: '5px 0',
          '&:hover': {
            backgroundColor: 'lightgray',
          },
          pl: 2
        }}
      >
        General
      </Box>
      <Box
        variant="contained"
        onClick={() => handleContentChange('Participants')}
        sx={{
          fontSize: {
            small: '8px',
            mobile: '8px',
            middle: '9px',
            tablet: '9px',
            large: '10px',
            desktop: '11px',
          },
          padding: '5px 0',
          '&:hover': {
            backgroundColor: 'lightgray',
          },
          pl: 2
        }}
      >
        Participants
      </Box>
      <Box
        variant="contained"
        onClick={() => handleContentChange('Groups')}
        sx={{
          fontSize: {
            small: '8px',
            mobile: '8px',
            middle: '9px',
            tablet: '9px',
            large: '10px',
            desktop: '11px',
          },
          padding: '5px 0',
          '&:hover': {
            backgroundColor: 'lightgray',
          },
          pl: 2
        }}
      >
        Groups
      </Box>
    </Box>
    <Box
      sx={{
        paddingLeft: 2,
        display: 'flex',
        flexDirection: 'column',
        width: '70%'
      }}
    >
      <Box sx={{ pl: 1, pr: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", width: '100%' }}>
        <Typography variant="h5">{panelElement}</Typography>
        {panelElement === 'Participants' && (
          <Button
            onClick={() => {
              dispatch(setElement('Adding Participant'));
            }}
            variant="addButton"
            sx={{
              width: {
                small: '100px',
                mobile: '100px',
                middle: '140px',
                large: '170px',
                desktop: '200px',
              },
              fontSize: {
                small: '7px',
                mobile: '7px',
                middle: '8px',
                tablet: '10px',
                large: '11px',
                desktop: '12px',
              },
              display: 'inline-block',
            }}
          >
            Add Participant
          </Button>
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        {panelElement === 'Participants' && <Participants />}
        {panelElement === 'Adding Participant' && <AddParticipant />}
        {panelElement === 'General' && <General item={item}></General>}
        {panelElement === 'Groups' && <Groups></Groups>}
      </Box>
    </Box>
  </Box>
</Modal>
  );
};

export default Settings;
