import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    element: 'dashboard',

}

const profileSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setElement: (state, action) => {
            state.element = action.payload;
        },
        resetElement: () => initialState,
    }
});
export const { setElement, resetElement } = profileSlice.actions;
export default profileSlice.reducer;