import React, { useEffect, useState, memo } from 'react';
import { Box, Typography, IconButton, Button, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import CustomAccordion from './QuestionsComponents/CustomAccordion'; 
import { setQuestions, addQuestion } from '../../redux/questionSlice';

const boxStyle = { mb: 3 };

const Questions = memo(() => {

    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.auth.accessToken);
    const spaceId = useSelector((state) => state.workspace.selectedSpace);
    const groups = useSelector((state) => state.group.groups);
    const data = useSelector(state => state.question.items);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        dispatch(setQuestions(accessToken, spaceId))
    }, []);

    return (
        <>
            {groups.length > 0 && (
                <Box sx={{ width: '95%' }}>
                    <Typography
                        sx={{
                            mb: 3,
                            fontWeight: 600,
                            fontSize: {
                                small: '15px',
                                mobile: '15px',
                                middle: '18px',
                                tablet: '20px',
                                large: '23px',
                                desktop: '25px',
                            },
                        }}
                    >
                        FAQ
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'row', pb: 4 }}>
                        {!adding && (
                            <IconButton onClick={() => setAdding(true)}>
                                <AddIcon />
                            </IconButton>
                        )}
                        {adding && (
                            <Formik
                                initialValues={{
                                    type: '',
                                    title: '',
                                    answer: '',
                                }}
                                onSubmit={(values) => {
                                    dispatch(addQuestion(accessToken, spaceId, values));
                                    setAdding(false);
                                }}
                            >
                                {({ handleChange }) => (
                                    <Form>
                                        <Field name="type">
                                            {({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Type"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                        </Field>

                                        <Field name="title">
                                            {({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Title"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                        </Field>

                                        <Field name="answer">
                                            {({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Answer"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                        </Field>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                            mt: 1
                                        }}>
                                        <Button
                                            type="submit"
                                            sx={{
                                                color: 'white',
                                                backgroundColor: 'black',
                                                padding: { small: '4px 10px', mobile: '5px 12px' },
                                                fontSize: { small: '0.625rem', mobile: '0.75rem' },
                                                marginRight: { small: 0.75, mobile: 1, middle: 2 },
                                                minWidth: 0, // Keeps the buttons compact
                                            }}
                                        >
                                            Add
                                        </Button>
                                        <Button
                                            sx={{
                                                color: 'white',
                                                backgroundColor: 'black',
                                                padding: { small: '4px 10px', mobile: '5px 12px' },
                                                fontSize: { small: '0.625rem', mobile: '0.75rem' },
                                                marginRight: { small: 0.75, mobile: 1, middle: 2 },
                                                minWidth: 0, // Keeps the buttons compact
                                            }}
                                            onClick={() => {
                                                setAdding(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>

                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Box>

                    {!adding && data.map((item, index) => (
                        <Box sx={boxStyle} key={`faq-box-${index}`}>
                            <CustomAccordion
                                answer={item.answerText}
                                title={item.questionText}
                                index={index}
                                id={item.id}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
});

export default Questions;