import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    items: [],
}

const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        setQuestionsReducer: (state, action) => {
            state.items = action.payload;
        },
        addQuestionReducer: (state, action) => {
            return {
                ...state,
                items: [...state.items, action.payload]
            }
        },
        removeQuestionsReducer: (state, action) => {
            return {
                ...state,
                items: state.items.filter(ws => ws.id !== action.payload)
            }
        },
        resetQuestions: () => initialState,
    }
});

export const setQuestions = (accessToken, spaceId) => async (dispatch) => {
    if (spaceId){
        await axios.get('/api/public/knowledge/space-questions', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-space-id': spaceId,
            },
        })
        .then((response) => {
            dispatch(setQuestionsReducer(response.data))
        });  
    } 
}

export const changeQuestion = (accessToken, spaceId, data, questionId) => async (dispatch) => {
    if (questionId){
        await axios.put(`/api/public/knowledge/space-questions/${questionId}/edit`, {
            "questionText": data.title,
            "answerText": data.answer
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'X-space-id': spaceId,
            }
        })
    }
}

export const addQuestion = (accessToken, spaceId,  data) => async (dispatch) => {
    console.log(spaceId)
    if (spaceId && spaceId !== '')
{
    console.log("send")
    await axios.post('/api/public/knowledge/space-questions/create', {
        "questionText": data.title,
        "answerText": data.answer
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'X-space-id': spaceId,
        },
    }).then(response => {
        dispatch(addQuestionReducer(response.data));
    })
    }    
}

export const removeQuestion = (accessToken, spaceId, questionId) => async (dispatch) => {
    if (spaceId){
        await axios.delete(`/api/public/knowledge/space-questions/${questionId}/delete`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-space-id': spaceId,
            }
        }).then(() => {
            dispatch(removeQuestionsReducer(questionId));
        })
    }
}

export const removeAllQuestions = (accessToken, spaceId) => async (dispatch) => {
    if (spaceId){
        await axios.delete('/api/public/knowledge/space-questions/delete-all', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-space-id': spaceId,
            }
        })
    }
}

export const { addQuestionReducer, setQuestionsReducer, removeQuestionsReducer, resetQuestions } = questionSlice.actions;
export default questionSlice.reducer;